import React, {useState} from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import * as Common from "../../Common/common.js";
import mainBg from "../../assets/img/bg-esg-04.jpg";

function EthicalMgmt() {
    // 권한 체크
    const authCk = Common.fn_authChek();

    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 파일 데이터
    const [imgFile, setImgFile] = useState([]);

    // 파일 변경 데이터 set
    const handleChangeFile = (event) => {
        setImgFile(event.target.files); 
    }

    return (<>
        {/* // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button onClick={()=>{navigate("/esg/visionObjective")}}>ESG</Button>
        //         <Button onClick={()=>{navigate("/esg/enviroMgmt")}}>환경경영</Button>
        //         <Button onClick={()=>{navigate("/esg/humanRightMgmt")}}>인권경영</Button>
        //         <Button onClick={()=>{navigate("/esg/safetyMgmt")}}>안전보건경영</Button>
        //         <Button onClick={()=>{navigate("/esg/ethicalMgmt")}}>윤리경영</Button>
        //         <Button onClick={()=>{navigate("/esg/socialContirb")}}>사회공헌</Button>
        //     </ButtonGroup>
        //     <br/>
        //     ESG 윤리경영 화면입니다.
        //     <br/>
        //     {
        //         authCk && <>
        //                     <input type="file" onChange={handleChangeFile} multiple/>
        //                     <Button onClick={()=>{Common.fn_fileUpload(imgFile, "eth")}}>위피아 윤리현장 및 윤리규범 업로드</Button>{" "}
        //                   </>
        //     }
        //     <Button onClick={()=>{Common.fn_fileDownload("eth")}}>위피아 윤리현장 및 윤리규범 다운로드</Button>{" "}
        //     <Button onClick={()=>{navigate("/esg/ethicalReport")}}>윤리규범 위반 제보</Button>
        // </div> */}
	<main className="page-wrapper">
		<Header />
		
         {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />
							  사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">윤리경영</li>
				</ol>
			</nav>
			
			<section className="container my-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">윤리경영</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아는 투명하고 공정한 경영활동을 통해 청렴한 기업문화를 정착하고 다양한 이해관계자로부터 신뢰와 존경을 받는 기업이 되기 위해 노력합니다.</p>
					</div>
			</section>
			
			{/* <!-- 적용 범위 및 위피아 5대 윤리 현장 --> */}
			<section className="container mb-5 pb-2 pb-md-4 pb-lg-5">
				<div className="card border-0 bg-secondary p-md-5 px-sm-2 pt-4 pb-3">
					<div className="card-body mx-auto" style={{maxWidth: "1160px"}}>
						<h2 className="h3 mb-4 text-center">적용 범위 및 위피아 5대 윤리 헌장</h2>
						<p className="py-4 pb-3 fs-5">위피아의 모든 임직원은 법규를 준수하는 동시에 본 윤리헌장 및 윤리규범에 따라 업무를 수행해야 하며, 회사는 모든 경영활동에서 의사결정과 행동의 판단 기준으로 삼는다.</p>
						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3 mt-5">
							<div className="row g-0">
								<div className="col-md-1 text-dark fw-semibold ps-4 pe-2 mt-4"><i className="bx bx-check-circle me-2 fs-5 text-primary"></i>하나</div>
								<div className="col-md-11">
									<div className="col px-4 py-4">우리는 정직과 공정의 신념으로 올바른 가치관을 확립하고 끊임없는 자기개발과 공정한 직무수행을 통해 주어진 사명을 완수한다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-1 text-dark fw-semibold ps-4 pe-2 mt-4"><i className="bx bx-check-circle me-2 fs-5 text-primary"></i>둘</div>
								<div className="col-md-11">
									<div className="col px-4 py-4">우리는 모든 임직원을 한 인간으로서 존중하고 능력과 업적에 따라 공정하게 대우하며 임직원의 창의성이 충분히 발휘되도록 노력한다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-1 text-dark fw-semibold ps-4 pe-2 mt-4"><i className="bx bx-check-circle me-2 fs-5 text-primary"></i>셋</div>
								<div className="col-md-11">
									<div className="col px-4 py-4">우리는 모든 사업활동에서 관계법규를 준수하며, 정당한 방법과 실력으로 경쟁의 우위를 확보한다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-1 text-dark fw-semibold ps-4 pe-2 mt-4"><i className="bx bx-check-circle me-2 fs-5 text-primary"></i>넷</div>
								<div className="col-md-11">
									<div className="col px-4 py-4">우리는 고객의 의견을 항상 존중하고, 고객에게 도움이 되는 가치를 끊임없이 창출하여 제공함으로써 고객으로부터 신뢰를 확보한다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-1 text-dark fw-semibold ps-4 pe-2 mt-4"><i className="bx bx-check-circle me-2 fs-5 text-primary"></i>다섯</div>
								<div className="col-md-11">
									<div className="col px-4 py-4">우리는 합리적인 사업전개를 통해 건실한 기업으로 성장함으로써 다양한 이해관계자가 함께 번영할 수 있는 지속 가능한 발전을 구현하는데 기여한다.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			{/* <!-- 윤리위반 제보 --> */}
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3 mb-7">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">윤리위반 제보</h2>
				<div className="col-12">
					<p className="mb-0 h1-sub">임직원의 불공정한 업무처리, 부당한 요구 등 각종 위법 행위에 대한 제보를 받고 있습니다. 신고 및 제보를 해주신 분이 불이익을 당하지 않도록 철저한 비공개 방식으로 처리됩니다.</p>
				</div>
				<div className="pt-md-5 pt-2 text-center">
					{/* <a href="portfolio-grid.html" className="btn btn-lg btn-primary w-sm-auto w-100 me-3 mt-2">위피아 윤리현장 및 윤리규범 다운로드</a>
                    <a href="portfolio-grid.html" className="btn btn-lg btn-primary w-sm-auto w-100 mt-2">윤리규범 위반 제보</a> */}

                    <button className="btn btn-lg btn-primary w-sm-auto w-100 me-3 mt-2" onClick={()=>{Common.fn_fileDownload("eth")}}>위피아 윤리헌장 및 윤리규범 다운로드</button>
                    {/* <button className="btn btn-lg btn-primary w-sm-auto w-100 mt-2" onClick={()=>{navigate("/esg/")}}>윤리규범 위반 제보</button> */}
                    <Link to="/ethicalReport" className="btn btn-lg btn-primary w-sm-auto w-100 mt-2">윤리규범 위반 제보</Link>
				</div>
			</section>
		</main>
	<Footer />

        </>
    )
}

export default EthicalMgmt;