import React , {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import * as Common from "../../Common/common.js";
import instance from "../../axios_interceptor";

import mainBg from "../../assets/img/bg-company-02.jpg";

function ComHisPubList() {
    // 권한 체크
    const authCk = Common.fn_authChek();

    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 연혁 리스트 data
    const [comYearList, setComYearList] = useState([]);
    let index = 1;

    // 최초로딩시 한번
    useEffect(() => {
        // 회사 연혁 조회
        fn_selectAdminYearList();
    }, []);

    // 최초로딩시 한번
    useEffect(() => {
    }, [comYearList]);

    // 회사 연혁 조회하기
    const fn_selectAdminYearList = async () => {
        await axios.post("/selectYearList")
        .then((response) => {
            console.log(response.data.rtnData)
            setComYearList(response.data.rtnData);
        })
        .catch((error) => {
        // 예외 처리
        })
    }

    

    return (
        // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" classNameName="mb-2">
        //         <Button onClick={()=>{navigate("/com/comOverview")}}>회사개요</Button>
        //         <Button onClick={()=>{navigate("/com/coreCompetence")}}>핵심역량</Button>
        //         <Button onClick={()=>{navigate("/com/comHisPubList")}}>회사연혁</Button>
        //     </ButtonGroup>
        //     <br/>
        //     모두 접근 가능한 회사연혁 리스트 화면입니다.
        //     {
        //         authCk && <div style={{float:"right", margin:"10px"}}>
        //                     <Button onClick={()=>{navigate("/com/comHisPrivList")}}>관리자 회사 연혁목록</Button>
        //                   </div>
        //     }
            
        //     <br/>
        //     <Table bordered>
        //         <colgroup>
        //             <col width="10%;" />
        //             <col width="10%;" />
        //             <col/>
        //         </colgroup>
        //         <thead>
        //             <tr>
        //                 <th>년</th>
        //                 <th>월</th>
        //                 <th>프로젝트명</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {comYearList.map((yearList) => {
        //                 let yearRow = 0;
        //                 let yearck = true;
                        
        //                 for(let i=0; i<yearList.monthList.length; i++) {
        //                     yearRow += yearList.monthList[i].rowList.length;
        //                 }

        //                 return (
        //                     yearList.monthList.map((month) => {
        //                         let monthRow = month.rowList.length;
        //                         let monthck = true;
        //                         return (
        //                             month.rowList.map((row) => {
        //                                 return (
        //                                     <tr key={"index"+(index++)} test={index}>
        //                                         {yearck && <td rowSpan={yearRow} align="center">{row.year}</td>}
        //                                         {monthck && <td rowSpan={monthRow} align="center">{row.month}</td>}
        //                                         <td>{row.taskContent}</td>
        //                                         {yearck=false}
        //                                         {monthck=false}
        //                                     </tr>
        //                                 )
        //                             })
        //                         )
        //                     })
        //                 )
        //             })}
        //         </tbody>
        //     </Table>
        // </div>
        <>
    <main className="page-wrapper">
        <Header />
	    
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">회사연혁</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 끊임없는 도전을 통해 미래가치를 창출합니다.</p>
						</div>
					</div>

				</div>
			</section>
			 
			{/* <!-- Breadcrumb --> */}
			<nav className="container pt-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">Company</li>
				  <li className="breadcrumb-item" aria-current="page">회사연혁</li>
				</ol>
			</nav>

            <section className="container">
				<div className="tab-pane fade show active" role="tabpanel">
					<ul className="nav nav-tabs" role="tablist">
						 <li className="nav-item ">
						  <a className="nav-link active d-block text-center " href="#history1" data-bs-toggle="tab" role="tab" style={{width: '9rem'}}>2021~현재</a>
						</li>
						<li className="nav-item">
						  <a className="nav-link d-block text-center" href="#history2" data-bs-toggle="tab" role="tab" style={{width: '9rem'}}>2011~2020</a>
						</li>
						<li className="nav-item">
						  <a className="nav-link d-block text-center" href="#history3" data-bs-toggle="tab" role="tab" style={{width: '9rem'}}>2004~2010</a>
						</li>
					</ul>
				  
					<div className="tab-content mt-n1">
						
						{/* <!-- 2021~현재 --> */}
						<div className="tab-pane fade show active" id="history1" role="tabpanel">
							<section className="py-4 mb-6">								
								<div className="position-relative container my-lg-5 my-md-4 my-3 py-xl-3">
									<div className="row">
										<div className="col-lg-4 text-lg-start pb-lg-0 mb-lg-0">
											<h3 className="h3">위피아의 핵심 역량</h3>
											<p className="fw-medium mt-7">WIPIA의 핵심역량은 Greative한 사업기획력과<br />뛰어난 IT 기술력을 보유한 우수한 인력들 입니다.</p>
										</div>

                                        <div className="col-xl-7 col-lg-8">
											<div className="row">
                                                {
                                                comYearList.map((yearList, index) => {
                                                    if(yearList.year > '2020')
                                                    return (<NewYear 
                                                        topIndex = {index}
                                                        year={yearList.year} 
                                                        monthList={yearList.monthList} 
                                                    /> )
                                                })

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* <!-- 2011~2020 --> */}
						<div className="tab-pane fade" id="history2" role="tabpane2">
							<section className="py-4 mb-6">								
								<div className="position-relative container my-lg-5 my-md-4 my-3 py-xl-3">
									<div className="row">
										<div className="col-lg-4 text-lg-start pb-lg-0 mb-lg-0">
											<h3 className="h3">위피아의 핵심 역량</h3>
											<p className="fw-medium mt-7">WIPIA의 핵심역량은 Greative한 사업기획력과<br />뛰어난 IT 기술력을 보유한 우수한 인력들 입니다.</p>
										</div>

                                        <div className="col-xl-7 col-lg-8">
											<div className="row">
                                                {
                                                comYearList.map((yearList, index) => {
                                                    if(yearList.year > '2010' && yearList.year <= '2020')
                                                    return (<NewYear 
                                                        topIndex = {index}
                                                        year={yearList.year} 
                                                        monthList={yearList.monthList} 
                                                    /> )
                                                })

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        {/* <!-- 2004~2010 --> */}
						<div className="tab-pane fade" id="history3" role="tabpane3">
							<section className="py-4 mb-6">								
								<div className="position-relative container my-lg-5 my-md-4 my-3 py-xl-3">
									<div className="row">
										<div className="col-lg-4 text-lg-start pb-lg-0 mb-lg-0">
											<h3 className="h3">위피아의 핵심 역량</h3>
											<p className="fw-medium mt-7">WIPIA의 핵심역량은 Greative한 사업기획력과<br />뛰어난 IT 기술력을 보유한 우수한 인력들 입니다.</p>
										</div>

                                        <div className="col-xl-7 col-lg-8">
											<div className="row">
                                                {
                                                comYearList.map((yearList, index) => {
                                                    if(yearList.year <= '2010')
                                                    return (<NewYear 
                                                        topIndex = {index}
                                                        year={yearList.year} 
                                                        monthList={yearList.monthList} 
                                                    /> )
                                                })

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>


                    </div>
                </div>
            </section>
        </main>
    <Footer />
        </>
    )
}

function NewTask(props){

    return (
        <div key={props.index} className="history-month-desc-box"> 
			<p className="history-month-desc">{props.task}</p> 
		</div> 
    );
}

function NewMonth(props){

    return (
        <li key={props.index} className="history-month-item"> 
            <div className="history-month-title">{props.month}월</div> 
            <div className="history-month-detail"> 
                {props.rowList.map((row, index) => {
                    return <NewTask index={index} task={row.taskContent} />
                })}
             
            </div>
        </li> 
    );

}

function NewYear(props){
    
    return (
        <div key={props.topIndex} className={props.topIndex == 0 ? 'history-wrap-current' : 'history-wrap'}> 
			<div className="history-year-area"><span className="blue">{props.year}</span></div> 
			<div className="history-month-area"> 
				<ul className="history-month-list"> 
					{props.monthList.map((month, index) => {
                        
                        return(
                            <NewMonth index={index}
                                month={month.month}
                                rowList={month.rowList}
                            />
                        );
                    })

                    }
				</ul> 
			</div> 
		</div>

    );
}

export default ComHisPubList;