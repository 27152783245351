import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-esg-01.jpg";
import esg01 from "../../assets/img/esg-01.jpg";
import esg02 from "../../assets/img/esg-02.jpg";
import esg03 from "../../assets/img/esg-03.jpg";

function VisionObjective() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (
        <>
		<main className="page-wrapper">
			<Header />
			
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item"> ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">ESG</li>
				</ol>
			</nav>
			
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">ESG 비전 및 목표</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아는 급변하는 경영환경 속에서도 사회적 역할과 책임을 다하여 새로운 가치를 창출하고 지속가능한 기업으로 발전시켜나가기 
						위한 노력을 아끼지 않겠습니다.</p>
					</div>
			</section>
			
			{/* <!-- 비전및 목표 --> */}
			<section className="bg-secondary py-5">
				 <div className="bg-secondary rounded-3 my-2 my-md-4 px-3 px-sm-6">
					<h1 className="h3 mb-sm-5 py-3 text-lg-center text-lg-start" style={{color:"#002b7b"}}>지속가능한 소프트웨어를 개발하여 사회적 가치 창출</h1>
					<div className="container bg-white rounded-3 my-2 my-md-4 py-5 px-3 px-sm-6">
						<div className="steps steps-horizontal-md steps-center mt-4">
							<div className="step">
								<div className="step-number">
									<div className="step-number-inner">1</div>
								</div>
								<div className="step-body">
									<h5 className="px-sm-5 esg-1" >안전한 근무환경 조성 및 지역사회 기여를 통한 사회적 책임성 강화</h5>
								</div>
							</div>
							<div className="step">
								<div className="step-number">
									<div className="step-number-inner">2</div>
								</div>
								<div className="step-body">
									<h5 className=" esg-1">온실가스 감축 목표 달성</h5>
								</div>
							</div>
							<div className="step">
								<div className="step-number">
									<div className="step-number-inner">3</div>
								</div>
								<div className="step-body">
									<h5 className=" esg-1">투명하고 공정한 경영을 통한<br />청렴한 기업문화 정착</h5>
								</div>
							</div>
						</div>
					</div>
				</div>				 
			</section>


			{/* <!-- 추진전략 --> */}
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">추친전략</h2>
				<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
					{/* <!-- 친환경 경영 --> */}
					<div className="col pb-1 pb-lg-3 mb-4 px-4">
						<article className="card h-100 border-0 shadow-sm">
							<div className="position-relative">
								<a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link"></a>
								<img src={esg01} className="card-img-top" />
							</div>
							<div className="card-body pb-3 ">
								<p className="esg-core-blue mb-0 text-center">친환경 경영</p>
								<h3 className="h2 mb-4 text-center">Environmental</h3>
								<h3 className="h5 mb-2">
								  위피아는 전사 환경 방침 및 실무 추진 조직을 구성함과 동시에 환경 영향을 최소화하기 위해 에너지 절감 활동과 온실가스 감축을 위한 다회용 개인컵 사용 등 임직원들이 참여하는 친환경 캠페인을 시행하고 있습니다.
								</h3>              
							</div>
						</article>
					</div>
					
					{/* <!-- 사회 --> */}
					<div className="col pb-1 pb-lg-3 mb-4 px-4">
						<article className="card h-100 border-0 shadow-sm">
							<div className="position-relative">
								<a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link"></a>
								<img src={esg02} className="card-img-top" />
							</div>
							<div className="card-body pb-3">
								<p className="esg-core-blue mb-0 text-center">사회</p>
								<h3 className="h2 mb-4 text-center">Social</h3>
								<h3 className="h5 mb-4">
								  위피아는 기업의 경영할동 과정에서 직.간접적으로 일어날 수 있는 모든 인권 침해를 예방하고 관련리스크를 완화하기 위해 인권경영체계를 구축하였으며, 안전한 근무환경 조성을 위해 안전 대응 체계를 구축하여 운영하고 있습니다.<br /><br />

									그리고 기업 이해관계자들의 자유와 권리를 보호하고 나아가 개인의 존엄과 가치를 실현하기 위해 개인정보보호 정책을 실행하고 있습니다. 또한 사회적 책임을 다하는 기업시민으로서의 역할 수행을 위해 지역 밀착형 사회공헌 활동을 개발하는 등 지속가능한 사회공헌 체계 구축에 힘쓰고 있습니다.
								</h3>              
							</div>
						</article>
					</div>
					
					{/* <!-- 지배구조 --> */}
					<div className="col pb-1 pb-lg-3 mb-4 px-4">
						<article className="card h-100 border-0 shadow-sm">
							<div className="position-relative">
								<a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0" aria-label="Course link"></a>
								<img src={esg03} className="card-img-top" />
							</div>
							<div className="card-body pb-3">
								<p className="esg-core-blue mb-0 text-center">지배구조</p>
								<h3 className="h2 mb-4 text-center">Governance</h3>
								<h3 className="h5 mb-2">
								  위피아는 투명하고 공정한 경영 활동을 통해 청렴한 기업문화를 정착하고, 다양한 이해관계자로부터 신뢰와 존경을 받는 기업이 되기 위해 윤리경영 체계를 구축하여 운영하고 있습니다.
								</h3>              
							</div>
						</article>
					</div>
				</div>
			</section>
		</main>
	<Footer />
        </>
    )
}

export default VisionObjective;