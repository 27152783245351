import React, {useState} from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import * as Common from "../../Common/common.js";
import mainBg from "../../assets/img/bg-esg-03.jpg";

function HumanRightMgmt() {
    // 권한 체크
    const authCk = Common.fn_authChek();

    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 파일 데이터
    const [imgFile, setImgFile] = useState([]);

    // 파일 변경 데이터 set
    const handleChangeFile = (event) => {
        setImgFile(event.target.files); 
    }

    return (<>
        {/* // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button onClick={()=>{navigate("/esg/visionObjective")}}>ESG</Button>
        //         <Button onClick={()=>{navigate("/esg/enviroMgmt")}}>환경경영</Button>
        //         <Button onClick={()=>{navigate("/esg/humanRightMgmt")}}>인권경영</Button>
        //         <Button onClick={()=>{navigate("/esg/safetyMgmt")}}>안전보건경영</Button>
        //         <Button onClick={()=>{navigate("/esg/ethicalMgmt")}}>윤리경영</Button>
        //         <Button onClick={()=>{navigate("/esg/socialContirb")}}>사회공헌</Button>
        //     </ButtonGroup>
        //     <br/>
        //     ESG 인권경영 화면입니다.
        //     <br/>
        //     {
        //         authCk && <>
        //                     <input type="file" onChange={handleChangeFile} multiple/>
        //                     <Button onClick={()=>{Common.fn_fileUpload(imgFile, "hr")}}>위피아 인권현장 업로드</Button>{" "}
        //                   </>
        //     }
        //     <Button onClick={()=>{Common.fn_fileDownload("hr")}}>위피아 인권현장 다운로드</Button>{" "}
        //     <Button onClick={()=>{navigate("/esg/humanReport")}}>인권침해 및 고충신고</Button>
        // </div> */}

<main className="page-wrapper">
	<Header />
		
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">인권경영</li>
				</ol>
			</nav>
			
			<section className="container my-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">인권경영</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아는 기업의 경영활동 과정에서 직·간접적으로 일어날 수 있는 모든 인권 침해를 예방하고 관련 리스크를 완화하기 위해 본 인권헌장을 선언한다.</p>
						<p className="mb-0 h1-sub mt-4">위피아는 인권경영을 위해 UN 세계인권선언, UN 기업과 인권 이행 지침, OECD 다국적기업 가이드라인, ILO 선언 등 인권, 노동 관련 각종 국제표준 및 가이드라인을 존중하고 준수하겠습니다.</p>
					</div>
			</section>
			
			{/* <!-- 인권현장 적용 범위 및 인권현장 --> */}
			<section className="container mb-5 pb-2 pb-md-4 pb-lg-5">
				<div className="card border-0 bg-secondary p-md-5 px-sm-2 pt-4 pb-3">
					<div className="card-body mx-auto" style={{maxWidth: "1160px"}}>
						<h2 className="h3 mb-4 text-center">인권현장 적용 범위 및 인권현장</h2>
						<p className="py-4 pb-3 fs-5">본 인권헌장의 적용 대상은 위피아 소속 임직원 및 모든 고객과 협력사를 포함하며, 거래 관계에 있는 모든 이해관계자가 본 인권헌장을 존중할 수 있도록 권장하여야 한다.</p>
						
						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3 mt-5">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">01. 차별금지</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 고용전반에 있어 차별을 금지하고 다양성을 존중합니다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">02. 성희롱 및 직장내 괴롭힘</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 성희롱 및 괴롭힘 행위를 금지하며, 피해자 보호를 위한 제도를 마련하고 예방을 위해
최선을 다합니다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">03. 안전 및 보건</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 재해 예방을 위한 안전·보건 관리 체계를 구축하고, 안전·보건 관련 법률을 준수합니다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">04. 아동노동 및 강제노동 금지</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 법정 고용 연령을 준수하며, 연소근로자 고용 시에는 관련 법률을 준수하며, 어떠한 형태의 강제 노동도 금지하며, 공정하고 투명한 근로계약을 체결합니다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">05. 근로조건 준수</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 법에서 정하고 있는 근로시간, 휴게시간, 휴일 규정을 준수하며 급여명세서와 함께 계약서에 명시된 금액을 정해진 일자에 지급합니다.</div>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="bg-white border-0 rounded-3 shadow-sm mb-3">
							<div className="row g-0">
								<div className="col-md-3 text-dark fw-semibold ps-4 pe-2 py-4">06. 고객 인권 및 개인정보 보호</div>
								<div className="col-md-9">
									<div className="col px-4 py-4">위피아는 임직원 및 이해관계자의 생명, 건강, 안전, 재산 등을 해치지 않도록 각별한 주의를 기울이며, 경영활동으로 수집한 개인정보의 보호를 위해 최선의 조치를 취합니다.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="pt-md-5 pt-2 text-center">
					{/* <a href="portfolio-grid.html" className="btn btn-lg btn-primary w-sm-auto w-100 me-3">위피아 인권현장 다운로드</a> */}
                    <button className="btn btn-lg btn-primary w-sm-auto w-100 me-3" onClick={()=>{Common.fn_fileDownload("hr")}}>위피아 인권현장 다운로드</button>
                    <Link to="/humanReport" className="btn btn-lg btn-primary w-sm-auto w-100">인권침해 및 고충 신고</Link>
				</div>
			</section>
		</main>
	<Footer />
        </>
    )
}

export default HumanRightMgmt;