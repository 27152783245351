import React, {useState, useRef} from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-busuness-05.jpg";
import const01 from "../../assets/img/construction-01.jpg";
import const02 from "../../assets/img/construction-02.jpg";
import const03 from "../../assets/img/construction-03.jpg";
import const04 from "../../assets/img/construction-04.jpg";
import const05 from "../../assets/img/construction-05.jpg";
import const06 from "../../assets/img/construction-06.jpg";
import const07 from "../../assets/img/construction-07.jpg";
import const08 from "../../assets/img/construction-08.jpg";
import const09 from "../../assets/img/construction-09.jpg";
import const10 from "../../assets/img/construction-10.jpg";
import const11 from "../../assets/img/construction-11.jpg";
import const12 from "../../assets/img/construction-12.jpg";
import const13 from "../../assets/img/construction-13.jpg";
import const14 from "../../assets/img/construction-14.jpg";

function ConstructionCase() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 하위메뉴 대상 화면명 데이터
    const [subMenuView, setSubMenuView] = useState("위치기반 물류관제");

    // selected 접근하기 위한 객체
    const selectRef = useRef(null);

    // M2M & LBS Solution, Mobile Solution, 환경모니터링 Solution 메뉴의 하위 메뉴리스트
    const subMenu = {
        sub1 : [
                  {name: "위치기반 물류관제", id:1}
                , {name: "안심서비스", id:2}
                , {name: "긴급출동 서비스", id:3}
                , {name: "DGT 차량운행모니터링", id:4}
                , {name: "G-CRM", id:5}
                , {name: "LBS 플랫폼", id:6}
                , {name: "커넥티드카", id:7}
        ], 
        sub2 : [
                  {name: "SmartView", id:1}
                , {name: "MDM (Mobile Aegis)", id:2}
                , {name: "MobileGW (Mobile Trueway)", id:3}
                , {name: "Smart 디지털 컨텐츠", id:4}
        ],
        sub3 : [
                  {name: "유해가스 모니터링 시스템", id:1}
                , {name: "공기오염측정 모니터링", id:2}
        ]
    };

    // 표시될 하위 메뉴 디폴트 set
    const [curSubMenu, setCurSubMenu] = useState(subMenu["sub1"]);

    // 표시될 하위 메뉴 변경 데이터 set
    const fn_subMenu = (sub) => {
        setCurSubMenu(subMenu[sub]);
        setSubMenuView(subMenu[sub][0].name);
        selectRef.current.value = "";
    }

    // 옵션값 변경에 따른 상세페이지 변경 데이터 set 
    const fn_subMenuView = (e) => {
        let index = curSubMenu.findIndex((menu)=>menu.id==e.target.value);
        setSubMenuView(curSubMenu[index].name);
    }

    return (
        // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button onClick={()=>{navigate("/busi/businessArea")}}>사업영역</Button>
        //         <Button onClick={()=>{navigate("/busi/businessSector")}}>사업분야</Button>
        //         <Button onClick={()=>{navigate("/busi/constructionCase")}}>구축사례</Button>
        //         <Button onClick={()=>{navigate("/busi/majorCustomer")}}>주요고객</Button>
        //     </ButtonGroup>
        //     <br/>
        //     구축사례 화면입니다.
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button onClick={()=>{fn_subMenu("sub1")}}>M2M & LBS Solution</Button>
        //         <Button onClick={()=>{fn_subMenu("sub2")}}>Mobile Solution</Button>
        //         <Button onClick={()=>{fn_subMenu("sub3")}}>환경모니터링 Solution</Button>
        //     </ButtonGroup>
        //     <br/>
        //     <Form.Select aria-label="Default select example" onChange={(e) => {fn_subMenuView(e)}} ref={selectRef}>
        //         {curSubMenu.map((menu)=>{
        //             return (
        //                 <option key={menu.id} value={menu.id}>{menu.name}</option>
        //             )
        //         })}
        //     </Form.Select>
        //     <br/>
        //     {subMenuView+" 화면이 올것입니다."}
        // </div>
        <>
		<main className="page-wrapper">
			<Header />
		
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">구축사례</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA의 고객과의 신뢰를 항상 최우선으로 하고 있습니다.</p>
						</div>
					</div>

				</div>
			</section>
			 
			{/* <!-- Breadcrumb --> */}
			<nav className="container pt-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link href="">Home</Link>
				  </li>
				  <li className="breadcrumb-item">Business</li>
				  <li className="breadcrumb-item active" aria-current="page">구축사례</li>
				</ol>
			</nav>
        
            <section className="container">
				<div className="tab-pane fade show active" role="tabpanel">
					<ul className="nav nav-tabs nav-fill" role="tablist">
						<li className="nav-item">
						  <a className="nav-link justify-content-center active"  href="#case1" data-bs-toggle="tab" role="tab">공공분야</a>
						</li>
						<li className="nav-item">
						  <a className="nav-link justify-content-center" href="#case2" data-bs-toggle="tab" role="tab">제조/물류 분야</a>
						</li>
						<li className="nav-item">
						  <a className="nav-link justify-content-center" href="#case3" data-bs-toggle="tab" role="tab">관제/모니터링 분야</a>
						</li>
						<li className="nav-item">
						  <a className="nav-link justify-content-center" href="#case4" data-bs-toggle="tab" role="tab">서비스 분야</a>
						</li>
					</ul>
				  
					<div className="tab-content mt-n1">
						
						{/* <!-- Case1:공공분야 --> */}
						<div className="tab-pane fade show active" id="case1" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 01</p>
												<h2 className=" mb-3 mb-sm-4">서울시 이동장치(PM) 주정차위반 신고시스템</h2>
												<p className="mb-3 mb-sm-4">
													불법 주·정차된 개인형 이동장치로 인한 시민 불편 민원이 증가함에 따라서, {/* <br /> */}
													쉽고 간단히 신고할 수 있는 신고시스템을 개발하여 대시민 신고를 접수하고 처리하는 시스템이며{/* <br /> */}
													서울시에 제공되었습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const01} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 2 --> */}
								<div className="row-non align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 02</p>
												<h2 className=" mb-3 mb-sm-4">해로드 앱 시스템</h2>
												<p className="mb-3 mb-sm-4">
													해양수산부 국립해양측위정보원에서 제공하는 소형선박 및 레저보트 이용자를 위한{/* <br /> */}
													전자해도 기반의 해양안전정보를 서비스하는 시스템 입니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const02} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 3 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 03</p>
												<h2 className=" mb-3 mb-sm-4">서울시 안심이 서비스 개발</h2>
												<p className="mb-3 mb-sm-4">
													스마트폰 위치정보와 서울시 정보인프라를 활용하여 각종 범죄 위협으로부터 시민을{/* <br /> */}
													보호하는 사회적 안전망 시스템 입니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const03} className="rounded-3" />
									</div>
									
								</div>
							</section>
						</div>
						
						{/* <!-- Case2 : 제조/물류 분야 --> */}
						<div className="tab-pane fade" id="case2" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 01</p>
												<h2 className=" mb-3 mb-sm-4">현대글로비스 완성차/철강 GTMS Mobile 시스템</h2>
												<p className="mb-3 mb-sm-4">자동차 제조공장에서 생산된 완성차를 TP수송 장비를 이용해 지역출고{/* <br /> */}
													센터 또는 수출 선적항으로 수송하고,{/* <br /> */}
													출고된 완성차를 고객이 원하는 시간과 장소에 안전하게 인도해주는 탁송{/* <br /> */}
													서비스 시스템이며 실시간 확인 가능한 위치추적 서비스를 제공합니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const04} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 2 --> */}
								<div className="row-non align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 02</p>
												<h2 className=" mb-3 mb-sm-4">현대글로비스 출고센터 모바일 PRS 시스템</h2>
												<p className="mb-3 mb-sm-4">
												완성차를 고객에게 인도하기 전 보관, 세차, 점검 등 PRS (Pre-releasing Service)를 통한 차량 출고 지원 {/* <br /> */}
												서비스이며 태블릿PC 기반의 시스템을 구축하였습니다. 
											</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const05} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 3 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 03</p>
												<h2 className=" mb-3 mb-sm-4">기아차 소하1공장 생산 품질 완결시스템</h2>
												<p className="mb-3 mb-sm-4">
													기아자동차 소하1공장의 실시간 공정품질 보증 및 제조품질 무결점 차량을 생산토록{/* <br /> */}
													하기 위한 생산품질 관리 시스템(차체, 의장, 도장, PDI, 수정장 검사업무)을 구축하였습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const06} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 4 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 04</p>
											  	<h2 className=" mb-3 mb-sm-4">현대모비스 모듈공장 운송비 정산시스템</h2>
											  	<p className="mb-3 mb-sm-4">
													현대모비스 모듈공장 운송차량의 운송비/정산 관리를 위해 통합 모니터링, 운송거리 분석 및 정산 기능을 구현하여 {/* <br /> */}
													운송비 정산 관리를 제공하는 시스템 입니다.
												</p>		
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const07} className="rounded-3" />
									</div>
									
								</div>
							</section>
						</div>
						
						{/* <!-- Case3:관제/모니터링 분야 --> */}
						<div className="tab-pane fade" id="case3" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 01</p>
												<h2 className=" mb-3 mb-sm-4">직행, 고속버스 통합관제 시스템</h2>
												<p className="mb-3 mb-sm-4">
													금호고속의 직행, 고속버스의 차량위치 및 상황실 관제를 위한 시스템으로{/* <br /> */}
													출발지 터미널에서 도착지 터미널까지의 모든 정보를 실시간 모니터링하고{/* <br /> */}
													관리하는 시스템을 구축하였습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const08} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 2 --> */}
								<div className="row-non align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 02</p>
												<h2 className=" mb-3 mb-sm-4">현대모비스 완성차 모듈 운송차량 직서열(JIS) 관제 시스템</h2>
												<p className="mb-3 mb-sm-4">
													현대자동차그룹의 (현대자동차/기아자동차) 완성차 조립공장 직서열부품공급시스템 (JIS - Just in Sequence)과 {/* <br /> */}
													연계하는 물류차량 서열 관리 관제시스템을 구축하였습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const09} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 3 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 03</p>
												<h2 className=" mb-3 mb-sm-4">탱크로리 수배송 관제 시스템</h2>
												<p className="mb-3 mb-sm-4">
													탱크로리 차량의 위치정보(GPS, 휴대폰 , 스마트폰 등) 와 전자지도 그리고 배차시스템 및 타 정보를 결합하여 추적된 위치정보를 기반으로{/* <br /> */}
													납품 진행정보, 차량의 상태, 운행상황, 실 시간 위치, 실 운행거리 등을 관제센터에서 파악, 분석하여 관리하는 시스템 입니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const10} className="rounded-3" />
									</div>
								</div>
								
								{/* <!-- Row 4 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 04</p>
												<h2 className=" mb-3 mb-sm-4">서산 주행시험장 차량관제 시스템</h2>
												<p className="mb-3 mb-sm-4">
													서산주행시험장의 시험로를 드나드는 모든 차량의 위치와 상태를 관제센터에서 실시간으로 확인하는 시스템으로,{/* <br /> */}
													모든 차량에 위치확인시스템(GPS)과 근거리통신장치를 부착하여 차량을 관제하는 시스템을 구축하였습니다.{/* <br /> */}
													GPS , GIS, 자이로센서, CCTV 연동 응용으로 주행시험 관제 구축 및 서비스 운영을 제공합니다. 
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const11} className="rounded-3" />
									</div>
								</div>
							</section>
						</div>
						
						{/* <!-- Case4:서비스 분야 --> */}
						<div className="tab-pane fade" id="case4" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 01</p>
												<h2 className=" mb-3 mb-sm-4">한일고속페리 예발매 시스템</h2>
												<p className="mb-3 mb-sm-4">
													한일고속의 고속페리 예매시스템으로 국내 최초 온라인 예매 지정좌석제 실시,{/* <br /> */}
													국내 연안여객선 최초 할인쿠폰 결제 연계, 연간 오픈 예약 기능 등이 구현된 선진화된 대한민국 연안여객선 예매시스템을 구축하였습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const12} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 2 --> */}
								<div className="row-non align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 02</p>
											  	<h2 className=" mb-3 mb-sm-4">쿠팡, 마켓컬리 - 통근버스관리시스템 서비스 구축</h2>
											  	<p className="mb-3 mb-sm-4">
													쿠팡,마켓컬리의 통근버스 관리시스템으로 운행노선의 실시간 관제를 제공하는 대시보드,{/* <br /> */}
													차량운행관리, 노선관리, 정류장관리, 배차관리, 분실물관리 등 셔틀버스의 전반적인 관리를 위해 구축한 시스템 입니다. {/* <br /> */}
													GPS, GIS, QR코드 ,카메라센서 응용으로 통근버스 관제 및 모바일 시스템을 구축하였습니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const13} className="rounded-3" />
									</div>
									
								</div>
								
								{/* <!-- Row 3 --> */}
								<div className="row align-items-lg-center pt-md-3 pb-5 mb-2 mb-lg-4 mb-xl-5">
									<div className="order-md-1">
										<div className="pe-xl-5 me-md-2 me-lg-4">
											<p className="con-blue-s">구축사례 03</p>
											  	<h2 className=" mb-3 mb-sm-4">보험사 스마트 긴급 출동 시스템</h2>
											  	<p className="mb-3 mb-sm-4">
													SK네트웍스 Penta One 구축 및 서비스 운영을 위한 시스템 입니다.{/* <br /> */}
													긴급 출동 서비스를 수행하기 위한 업무용 어플리케이션으로 출동관리, 배차관리, 실적확인 등{/* <br /> */}
													ERS 콜센터(CTI)와 연동된 시스템을 제공합니다.
												</p>
										</div>
									</div>
									<div className="order-md-2 mb-4 mb-md-0">
										<img src={const14} className="rounded-3" />
									</div>
									
								</div>
							</section>
						</div>
				
					</div>
				</div>
			</section>
		</main>
	<Footer />
        </>
    )
}

export default ConstructionCase;