import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-esg-08.jpg";
import subBg from "../../assets/img/bg-esg-05.jpg";
function SafetyMgmt() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
		
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">안전보건경영방침</li>
				</ol>
			</nav>
			
			<section className="container py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">안전보건경영방침</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아는 “임직원 및 협력사 직원의 건강과 공동체 안전”을 최우선 가치로 생각하며, 안전보건 성과의 지속적인 개선을 통해 안전하고 건강한 일터를 조성한다.</p>
					</div>
			</section>
			
			{/* <!-- 비전및 목표 --> */}
			<section className="bg-secondary py-3  mb-5">
				 <div className="bg-secondary rounded-3 my-2 my-md-4 px-3 px-sm-6">
					
					<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
						<div className="row row-cols-1 row-cols-md-3 esg_list_wrap">

							{/* <!-- Item --> */}
							<div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
								  <h2 className="h4 d-inline-flex align-items-center">01</h2>
								  <p className="text-body mb-0">회사는 안전보건 경영 의지를 지속적으로 표명하고, 임직원 및 협력사 직원은 이를 함께 성실하게 이행한다.</p>
								</div>
							  </div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
								 <h2 className="h4 d-inline-flex align-items-center">02</h2>
								  <p className="text-body mb-0">회사는 안전보건에 관한 법규 및 이해관계자의 요구사항을 준수하고 경영활동 시 책임과 의무를 다한다.</p>
								</div>
							  </div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
								  <h2 className="h4 d-inline-flex align-items-center">03</h2>
								  <p className="text-body mb-0">회사는 유해·위험 요인을 발굴하고 우선순위에 따라 지속적인 개선노력을 기울이며, 필요한 자원이 적기에 제공되도록 최선을 다한다.</p>
								</div>
							  </div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body">
								  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
								  <h2 className="h4 d-inline-flex align-items-center">04</h2>
								  <p className="text-body mb-0">회사는 임직원 및 협력사 직원 등 이해관계자의 안전과 보건의 유지·증진 및 사고예방을 위하여 적극적 소통, 자율적 협의 및 참여 환경을 적극적으로 보장·조성한다.</p>
								</div>
							  </div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
								  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
								  <h2 className="h4 d-inline-flex align-items-center">05</h2>
								  <p className="text-body mb-0">회사는 지속적인 교육훈련과 학습을 제공하여 안전의식을 제고하고 안전이 내재화된 조직문화를 만든다.</p>
								</div>
							  </div>
							</div>

							{/* <!-- Item --> */}
							{/* <div className="col py-4 my-2 my-sm-3">
							  <div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
								  <div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3">
									
								  </div>
								  <h2 className="h4 d-inline-flex align-items-center">06</h2>
								  <p className="text-body mb-0">기업의 사회적 책임에 대하여 깊이 인식하고 지역사회 환경보전활동에 적극 참여한다.</p>
								</div>
							  </div>
							</div> */}
						  </div>
						
					</div>
				</div>				 
			</section>
			
			
			{/* <!-- 이미지+타이틀 --> */}
			<section className="container py-4">
				<div className="bg-dark bg-size-cover bg-position-center bg-repeat-0 position-relative overflow-hidden rounded-3 py-lg-3 py-xl-5 px-4 px-lg-5 px-xl-0" style={{backgroundImage: `url(`+subBg+`)`}} data-bs-theme="dark">
					<div className="row px-sm-5 py-4 my-1 my-md-3">
						<h4 className="h5 mb-3 fs-5">Safety and health management system</h4>
						<h2 className="h2 pb-1 pb-md-3 pb-lg-4" style={{fontWeight: 'normal'}}>안전보건 경영 목표</h2>					
					</div>
				</div>
			</section>

			{/* <!-- 안전보건 목표 --> */}
			<section className="container my-2 py-lg-5 py-md-4 pt-2 pb-3 mb-6">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2 text-center"> 안전보건 목표</h2>
				
				<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row row-cols-1 row-cols-md-3 esg_list_wrap">

						{/* <!-- Item --> */}
						<div className="col my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">안전보건 개선</h2>
									<p className="mb-0 mt-3r">경영진은 사업장의 안전보건 활동을 위해 정기적으로 점검하며 임직원의 의견을 청취하고 개선한다.</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">안전보건 활동</h2>
									<p className="mb-0 mt-3r">임직원은 규정된 안전보건 활동에 주도적으로 참여하고 실행한다.</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">산업재해 예방</h2>
									<p className="mb-0 mt-3r">산업재해를 예방하기 위하여 연1회 예산을 수립하고, 연말1회 집행 내역을 보고한다.</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">유해·위험 요인</h2>
									<p className="mb-0 mt-3r">유해·위험 요인에 대한 사전발굴 및 개선 절차 마련, 반기1회 이행점검을 한다.</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">안전보건 경영성과</h2>
									<p className="mb-0 mt-3r">연1회 정기적으로 안전보건 경영성과를 평가하여 경영진에게 보고한다.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	<Footer />


        </>
    )
}

export default SafetyMgmt;