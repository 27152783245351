import React, {useState, createContext, useMemo} from "react";

export const ArgContext = createContext(null);

function ArgProvider ({ children }) {
    const [argInfo, setArgInfo] = useState({
        // 추후 전역변수 사용할 경우 사용
        //     token : sessionStorage.getItem("token")
        //   , refreshToken : sessionStorage.getItem("refreshToken")
    });

    const value = useMemo(()=>{
        return {argInfo, setArgInfo}
    }, [argInfo]);

    return (
        <ArgContext.Provider value={value}>
            {children}
        </ArgContext.Provider>
    );
}

export default ArgProvider;