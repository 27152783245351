import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-busuness-04.jpg";
import cons01 from "../../assets/img/business-13.jpg";
import cons02 from "../../assets/img/business-14.jpg";
import cons03 from "../../assets/img/business-15.jpg";
import cons04 from "../../assets/img/business-16.jpg";
import CallWipia from "./CallWipia";

function ITService() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
		
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">IT Service</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 IT융합 및 스마트 서비스로 고객에게 최적화된 비즈니스 서비스를 지원합니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item"> Business</li>
				  <li className="breadcrumb-item active" aria-current="page">IT Service</li>
				</ol>
			</nav>

			{/* <!-- Item 01 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons01} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">01</p>
						<h1 className="h2 mb-sm-4 con-left">Business</h1>
						<p className="fs-5  con-left" style={{maxWidth: "33rem"}}>WIPIA는 IT분야에 기술적 전문성을 토대로 고객의 비즈니스 이해에 기반한 가치 창출에 힘쓰고 있습니다. </p>
					</div>
				</div>
			</section>


			{/* <!-- Item 02 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons02}  />
						</div>
					</div>
					 <div className="col-md-6 mb-4">
						<p className="con-blue">02</p>
						<h1 className="h2 mb-sm-4">Customer</h1>
						<p className="fs-5" style={{maxWidth: "33rem"}}>‘고객중심’이라는 가치를 최우선으로 하여 고객이 추구하는 방향성에 부합하는 다양한 형태의 IT서비스를 제공합니다.</p>
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons03} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">03</p>
						<h1 className="h2 mb-sm-4 con-left">Innovation</h1>
						<p className="fs-5  con-left" style={{maxWidth: "31rem"}} >혁신은 WIPIA를 움직이는 중요한 엔진으로 조직 구조, 문화, 프로세스의 변화를 통해 다른 기업과 차별화 할 수 있도록 노력합니다.</p>
							</div>
				</div>
			</section>


			{/* <!-- Item 04 --> */}
			<section className="container mb-7">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons04} />
						</div>
					</div>
					<div className="col-md-6">
						<p className="con-blue">04</p>
						<h1 className="h2 mb-sm-4">Convergence</h1>
						<p className="fs-5" style={{maxWidth: "33rem"}}>과거-현재-미래로 이어지는 비즈니스 연속성을 위해 IT 기술 융합을 통하여 혁신적인 시스템과 솔루션을 구현합니다.</p>
					</div>
				</div>
			</section>

            <CallWipia />
		</main>
	<Footer />
			

        </>
    )
}

export default ITService;