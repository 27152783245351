import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-company-01.jpg";
import ceoImg from "../../assets/img/ceo-sign.jpg";
import comCeoImg from "../../assets/img/company-ceo.jpg";
import comOrgImg from "../../assets/img/company_organization.png";

function ComOverview() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return ( <>
	<main className="page-wrapper">
		<Header />
		
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">회사소개</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 고객의 가치창조 실현을 위하여 창의적 지식과 경쟁력을 지닌 고객중심 기업입니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item"> Company</li>
				  <li className="breadcrumb-item active" aria-current="page">회사소개</li>
				</ol>
			</nav>

			

			{/* <!-- Item 02 --> */}
			<section className="container pb-7">
				<h1 className="h1 mb-sm-4  pb-5">Great Think,<br />Great Act !</h1>
				<div className="row-non">
					
				  <div className="col-md-6">
					
					 <p className="mb-3">
						 (주)위피아는 2004년 12월 모바일(Wireless)과 사물인터넷(IoT)<br />
						 세상의 중심에서 미래가치 창출을 목표로 설립되었습니다.<br />
						 <br /><br />
						 (주)위피아의 핵심역량은 Greative한 기획력과 뛰어난 IT 전문가들 입니다.<br />
						 <br /><br />
					   이를 통해 (주)위피아는 IT서비스 전문기업으로 성장 발전할 수 있도록 최선을 다하겠습니다.
							
					  </p>
					  <h3 className="mt-8">위피아 대표이사{/*<img src={ceoImg} alt="Image" />*/}</h3>
					</div>
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div className="text-end">
						  <img src={comCeoImg} alt="Image" />
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="bg-secondary py-5">
				<div className="position-relative container my-lg-5 my-md-4 my-3 py-xl-3">
					<div className="row">
					  <h2 className="h2 pt-2 pb-lg-2 pb-md-0 pb-sm-2">조직도</h2>
					   <p className="mb-0 h1-sub mb-sm-6">위피아는 신뢰받고 인정받는 스마트 IT 서비스 기업이 되도록 최선을 다하겠습니다. </p>
						
						
					<div className="text-center px-sm-6 mt-5">
						  <img src={comOrgImg} alt="Image" />
						</div>
					</div>
				</div>
			</section>		
		</main>
	<Footer />
		
        </>
    )
}

export default ComOverview;