import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-esg-07.jpg";
import subBg from "../../assets/img/bg-esg-02.jpg";


function EnviroMgmt() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
		
		{/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">환경경영</li>
				</ol>
			</nav>
			
			<section className="container py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">환경경영</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아는 환경 영향을 최소화하고 자원 절감과 에너지의 효율적 이용을 위해 임직원, 고객 등 이해관계자와 함께 환경경영을 실천하여 지속가능한 발전을 도모한다.</p>
					</div>
			</section>
			
			{/* <!-- 비전및 목표 --> */}
			<section className="bg-secondary py-3  mb-5 my-2 my-md-4 px-3 px-sm-6">
				<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row row-cols-1 row-cols-md-3 esg_list_wrap">
						
						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 me-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">01</h2>
									<p className="text-body mb-0">기후 위기는 지구환경 보전을 위한 인류의 숙원 과제임을 인식하고, 자원과 에너지 절감으로 온실가스 발생을 최소화하기 위하여 노력한다.</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">02</h2>
									<p className="text-body mb-0">환경 관련 국내 법규 및 정책을 성실히 이행한다.</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">03</h2>
								  	<p className="text-body mb-0">환경경영 목표 및 추진계획을 세우고 성과를 평가함으로써 지속적인 개선이 이루어지도록 한다.</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">04</h2>
									<p className="text-body mb-0">환경경영에 필요한 물적 자원을 지원하고 체계적인 관리시스템을 운영하여 성과 향상에 노력한다.</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">05</h2>
									<p className="text-body mb-0">환경경영활동을 이해관계자에게 공유하고 환경인식 내재화 및 친환경 문화 확산에 동참한다.</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col py-4 my-2 my-sm-3">
							<div className="card h-100 border-0 shadow-sm pt-3 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2">
								<div className="card-body pt-3">
									<div className="d-inline-block bg-primary shadow-primary rounded-3 position-absolute top-0 translate-middle-y p-3"></div>
									<h2 className="h4 d-inline-flex align-items-center">06</h2>
									<p className="text-body mb-0">기업의 사회적 책임에 대하여 깊이 인식하고 지역사회 환경보전활동에 적극 참여한다.</p>
								</div>
							</div>
						</div>
					</div>
						
				</div>			 
			</section>
			
			
			{/* <!-- 타이틀+이미지 --> */}
			<section className="container py-4">
				<div className="bg-dark bg-size-cover bg-position-center bg-repeat-0 position-relative overflow-hidden rounded-3 py-lg-3 py-xl-5 px-4 px-lg-5 px-xl-0" style={{backgroundImage: `url(`+subBg+`)`}} data-bs-theme="dark">
					<div className="row px-sm-5 py-4 my-1 my-md-3">
						<h4 className="h5 mb-3 fs-8">Environmental</h4>
						<h2 className="h2 pb-1 pb-md-3 pb-lg-4" style={{fontWeight: "normal"}}>환경을 위한 경영전략 및 목표</h2>
					</div>
				</div>
			</section>

			
			{/* <!-- 경영전략 --> */}
			<section className="container my-2 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2 text-center">경영 전략</h2>
				
				<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row row-cols-1 row-cols-md-4">

						{/* <!-- Item --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">에너지 자원</h2>
									<p className="mb-0 mt-3r">에너지 자원의 효율적 관리를 통한 환경 경영 실천</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">환경경영 교육</h2>
									<p className="mb-0 mt-3r">환경경영 교육을 통한 임직원의 환경 의식 정착 및 변화 추진</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">온실가스 최소화</h2>
									<p className="mb-0 mt-3r">다양한 기후 변화 위기 대응을 통한 온실가스 배출량최소화</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">관리체계구축</h2>
									<p className="mb-0 mt-3r">환경법규 위반 및 환경사고 발생에 대한 예방 관리체계구축</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			
			{/* <!-- 경영목표 --> */}
			<section className="bg-secondary py-3 py-lg-5 py-md-4 pt-2 pb-3">
				<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
					<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2 text-center">경영 목표</h2>
					<div className="container my-2 my-md-4 py-2 px-3 px-sm-4">					
						<div className="row row-cols-1 row-cols-md-4">

							{/* <!-- Item --> */}
							<div className="col py-3 my-sm-3">
								<div className="card h-100">
									<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
										<span className="line-fill"></span>
										<h2 className="h7 d-inline-flex align-items-center mt-3">개인 목표</h2>
										<p className="mb-0 mt-3r">1인 1개이상 참여</p>
									</div>
								</div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-3 my-sm-3">
								<div className="card h-100">
									<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
										<span className="line-fill"></span>
										<h2 className="h7 d-inline-flex align-items-center mt-3">에너지 절감</h2>
										<p className="mb-0 mt-3r">전년대비 에너지 사용량 3% 절감 (2022년대비 2030년까지 20% 절감)</p>
									</div>
								</div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-3 my-sm-3">
								<div className="card h-100">
									<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
										<span className="line-fill"></span>
										<h2 className="h7 d-inline-flex align-items-center mt-3">온실가스 배출량 절감</h2>
										<p className="mb-0 mt-3">전년대비 온실가스 배출량 3% 절감 (2022년대비 2030년까지 20% 절감)</p>
									</div>
								</div>
							</div>

							{/* <!-- Item --> */}
							<div className="col py-3 my-sm-3">
								<div className="card h-100">
									<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
										<span className="line-fill"></span>
										<h2 className="h7 d-inline-flex align-items-center mt-3">환경 법규 준수</h2>
										<p className="mb-0 mt-3">환경 법규 위반 0건</p>
									</div>
								</div>
							</div>
							
						</div>
					</div>						
				</div>		 
			</section>
		</main>
	<Footer />
	</>
    );
}

export default EnviroMgmt;