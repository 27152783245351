import React from "react";
import wipiaImg from '../../assets/img/wipia.png';

function AdminFooter() {


    return (<>
        {/* <!-- Footer --> */}
		<footer className="footer pt-5 pb-4">
			<div className="container">
				<div className="row px-3">
					<div className="navbar-brand text-dark p-0 me-0 mb-5">
              			<img src={wipiaImg} width="80" alt="WIPIA" />
					</div>
					<div className="fs-sm p-0 mb-1 fw-medium">서울특별시 영등포구 경인로 775 1-507(문래동3가, 에이스하이테크시티)<span className="footer-link-line"></span>대표번호 : 02-2038-8311<span className="footer-link-line"></span>팩스번호 : 02-2038-8322</div>
					<div className="fs-sm mb-4 p-0 fw-medium">COPYRIGHT ⓒ 2013 WIPIA CO., LTD. ALL RIGHTS RESERVED.</div>
				</div>
			</div>
		</footer>
		
		{/* <!-- Sitemap modal--> */}
        <div className="modal fade" id="modalSitemap" tabindex="-1" role="dialog">
          <div className="modal-dialog modal-fullscreen" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-2 fw-medium mb-1"><img src={wipiaImg} width="90" alt="WIPIA" /></h5>
                <button className="btn-close mt-5 mb-1" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row row-cols-1 row-cols-md-5">

						{/* <!-- Company --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Company</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="company1-about.html">회사소개</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="company2-history.html">회사연혁</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="company3-certification.html">인증 및 협약</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- ESG --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">ESG</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="esg1.html">ESG</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="esg2-environment.html">환경경영</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="esg3-human.html">인권경영</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="esg4-safety.html">안전보건경영</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="esg5-ethical.html">윤리경영</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="esg6-social.html">사회공헌</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- Business --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Business</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="business1-consulting.html">IT Consulting</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="business2-system.html">System Integration</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="business3-solution.html">Solution</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="business4-service.html">IT Service</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="business5-construction.html">구축사례</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- Contact US --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Contact US</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="contactus.html">Contact Us</a></li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- Recruit --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Recruit</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="recruit.html">Recruit</a></li>
									</ul>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
              </div>
             
            </div>
          </div>
        </div>


		{/* <!-- Back to top button --> */}
		<a href="#top" className="btn-scroll-top mb-4" data-scroll>
			<span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
			<i className="btn-scroll-top-icon bx bx-chevron-up"></i>
		</a>
        
    </>);
}

export default AdminFooter;