import React from "react";
import { Link } from "react-router-dom";
import wipiaWImg from '../../assets/img/wipia-w.png';
import LoadExternalJS from "../../js/loadExternalJS";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./headerTheme.css";
function Header(props) {
	LoadExternalJS();
    return (
        <>
            <header className="header navbar navbar-expand-lg navbar-dark position-absolute navbar-sticky">
				<div className="container px-3">
					{/* <Link to="/" className="navbar-brand pe-3">
						<img src={wipiaWImg} width="100" alt="WIPIA" />
					</Link>
					<div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
						<div className="offcanvas-header border-bottom border-light">
							<h5 className="offcanvas-title text-white">Menu</h5>
							<button type="button" className="btn-close btn-close-white" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="false" aria-controls="collapseExample" aria-label="Close"></button>
						</div>
						<div className="offcanvas-body">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
								<li className="nav-item dropdown">
									<a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true">Company</a>
									<ul className="dropdown-menu dropdown-menu-dark">
										<li><Link to="/comOverview" className="dropdown-item">회사소개</Link></li>
										<li><Link to="/comHisPubList" className="dropdown-item">회사연혁</Link></li>
										<li><Link to="/coreCompetence" className="dropdown-item">인증 및 협약</Link></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">ESG</a>
									<ul className="dropdown-menu dropdown-menu-dark">
										<li><Link to="/visionObjective" className="dropdown-item">ESG</Link></li>
										<li><Link to="/enviroMgmt" className="dropdown-item">환경경영</Link></li>
										<li><Link to="/HumanRightMgmt" className="dropdown-item">인권경영</Link></li>
										<li><Link to="/safetyMgmt" className="dropdown-item">안전보건경영</Link></li>
										<li><Link to="/ethicalMgmt" className="dropdown-item">윤리경영</Link></li>
										<li><Link to="/socialContirb" className="dropdown-item">사회공헌</Link></li>
									</ul>
								</li>
								<li className="nav-item dropdown">
									<a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Business</a>
									<ul className="dropdown-menu dropdown-menu-dark">
										<li><Link to="/iTConsulting" className="dropdown-item">IT Consulting</Link></li>
										<li><Link to="/systemIntegration" className="dropdown-item">System Integration</Link></li>
										<li><Link to="/solution" className="dropdown-item">Solution</Link></li>
										<li><Link to="/iTService" className="dropdown-item">IT Service</Link></li>
										<li><Link to="/constructionCase" className="dropdown-item">구축사례</Link></li>
									</ul>
								</li>
								<li className="nav-item">
									<Link to="/locationInfo" className="nav-link">Contact Us</Link>
                  
								</li>
								<li className="nav-item">
									<Link to="/recruitmentGuide" className="nav-link">Recruit</Link>
								</li>
							</ul>
						</div>
					</div>
				
					<button type="button" className="navbar-toggler pb-5" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button> */}
					<Navbar expand="lg" bg="primary" data-bs-theme="dark" className="bg-body-tertiary" fixed="top">
						<Container>
							<Navbar.Brand href="/">
						<img src={wipiaWImg} width="100" alt="WIPIA" />
					</Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="me-auto">
								
								<NavDropdown title="Company" id="basic-nav-dropdown" >
									<NavDropdown.Item as={Link} to="/comOverview" >회사소개</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/comHisPubList">회사연혁</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/coreCompetence">인증 및 협약</NavDropdown.Item>
								</NavDropdown>

								<NavDropdown title="ESG" id="basic-nav-dropdown">
									<NavDropdown.Item as={Link} to="/visionObjective">ESG</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/enviroMgmt">환경경영</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/HumanRightMgmt">인권경영</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/safetyMgmt">안전보건경영</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/ethicalMgmt">윤리경영</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/socialContirb">사회공헌</NavDropdown.Item>
								</NavDropdown>
								
								<NavDropdown title="Business" id="basic-nav-dropdown">
									<NavDropdown.Item as={Link} to="/iTConsulting">IT Consulting</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/systemIntegration">System Integration</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/solution">Solution</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/iTService">IT Service</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/constructionCase">구축사례</NavDropdown.Item>
								</NavDropdown>
								

								<Nav.Link as={Link} to="/locationInfo">Contact Us</Nav.Link>
								<Nav.Link as={Link} to="/recruitmentGuide">Recruit</Nav.Link>
							</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</div>
			</header>
        
        </>

    );
}

export default Header;