import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-esg-09.jpg";
function Privacy (){

    return (<>
	<main className="page-wrapper">
		<Header />
		
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
							{/* <!--<h1 className="display-2 mt-5 mb-md-4">개인정보보호방침</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>--> */}
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">개인정보보호방침</li>
				</ol>
			</nav>
			
			<section className="container py-md-4 pt-2 pb-3 mb-5">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">개인정보보호방침</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아(이하 “회사”)는 “개인정보보호법”에 근거하여 개인정보의 처리 및 보호에 관한 사항을 정함으로써 기업의 이해관계자들(임직원 및 고객사 등)의 자유와 권리를 보호하고, 나아가 개인의 존엄과 가치를 구현하기 위해 개인정보보호 방침을 제정함.</p>
					</div>
			</section>				
			


			{/* <!-- 개인정보보호방침 --> */}
			<section className="container py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h3 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">I. 총칙</h2>
				<article className="sub_con mb-6">
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">1. 용어 정의</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">
								”개인정보”란 살아 있는 개인에 관한 정보로서 다음 각 목에 해당하는 정보를 말한다.
								  
								 가) 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보<br />
								나) 해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보. 이 경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데 소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 한다.
								
							  </li>
							  <li className="small" data-custom="②">“정보주체” 란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말한다.</li>
							  <li className="small" data-custom="③">“처리” 란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 , 그 밖에 이와 유사한 행위를 말한다.</li>
								<li className="small" data-custom="④">“개인정보처리자” 란 업무를 목적으로 개인정보파일을 운용하기 위해 스스로 또는 다른 사람을 통해 개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다.</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">2. 적용 범위</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">보호 및 관리대상 : 회사의 업무를 목적으로 수집한 이해관계자들(임직원 및 고객정보 등)</li>
							</ul>
						  </div>
						</div>
					</div>
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">3. 개인정보 보호 원칙</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">회사는 개인정보의 처리 목적을 명확하게 해야 하고 그 목적에 필요한 범위에서 최소한의 정보만을 적법하고 정당하게 수집해야 한다.</li>
							  <li className="small" data-custom="②">개인정보의 처리 목적에 필요한 범위에서 적합하게 개인정보를 처리해야 하며, 그 목적 외의 용도로 활용해서는 안 된다. 기업에게 개인정보를 제공한 이해관계자들은 개인의 정보처리에 관한 정보를 제공받거나, 정보 처리에 관한 동의 선택 등 개인정보 처리와 관련한 권리를 가질 수 있습니다.</li>
							  <li className="small" data-custom="③">개인정보처리자는 개인정보의 처리 방법 및 종류 등에 따라 정보주체의 권리가 침해받을 가능성과 그 위험 정도를 고려하여 개인정보를 안전하게 관리하여야 한다.</li>
							  <li className="small" data-custom="④">개인정보처리자는 정보주체의 사생활 침해를 최소화하는 방법으로 개인정보를 처리하여야 한다.</li>
								<li className="small" data-custom="⑤">개인정보처리자는 개인정보 보호법 및 관계 법령에서 규정하고 있는 책임과 의무를 준수하고 실천함으로써 정보주체의 신뢰를 얻기 위하여 노력하여야 한다.</li>
							</ul>
						  </div>
						</div>
					</div>
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">4. 개인정보의 처리</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">회사는 이해관계자들의 동의를 받았거나 계약의 체결 및 이행을 위해 불가피하게 필요한 경우 등에는 개인정보를 수집할 수 있다. 이 때 목적에 필요한 최소한의 개인정보를 수집해야 한다.</li>
							  <li className="small" data-custom="②">회사는 정보주체의 동의를 받았거나 법에 의거한 특수한 경우 제3자에게 정보를 공유하거나 제공할 수 있으며 이 경우에도 이해관계자들에게 상황에 대한 설명 및 동의를 구해야 한다.</li>
							  <li className="small" data-custom="③">회사는 개인정보의 수집. 이용목적, 수집하려는 개인정보의 항목, 개인정보의 보유 및 이용기간을 변경하는 경우에도 이를 알리고 동의를 받아야 한다.</li>
							  <li className="small" data-custom="④">회사는 보유기간의 경과, 개인정보의 처리 목적 달성 등 개인정보가 불필요하게 되었을때에는 정당한 사유가 없는 한 그로부터 5일 이내에 개인정보를 파기해야 하며, 파기 시 복구 또는 재생되지 않도록 조치해야 한다.<br />
							파기를 하지 않고 보존해야 할 경우 해당 정보는 다른 정보와 분리해서 저장 및 관리해야 한다.</li>
								<li className="small" data-custom="⑤">회사의 정보(고객 정보, 지적재산 등)가 무단으로 활용되지 않도록 이에 대한 보호절차와 보안시스템 (방화벽, IPS, 스팸 차단, 웹 보안, DB 접근제어 등) 및 계약상의 보호조치를 해야 한다.</li>
								<li className="small" data-custom="⑥">근로자들의 개인정보, 정보보호와 관련한 고충처리 및 상담, 인사정보 등을 취급하는 담당 부서 및 담당자(개인정보 보호 책임자)를 지정하거나 변경할 경우 담당자의 지정 및 변경 사실, 성명과 부서의 명칭, 전화번호 등 연락처를 공개해야 한다. 관리자는 정보보호 관련 법령 및 제도의 내용, 업무 수행에 필요한 사항 및 정보보호 절차 등에 대한 정기적인 교육을 이행해야 한다.</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">5. 개인정보의 안전한 관리</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">개인정보의 수집, 보관, 처리, 전송 및 공유에 관한 법률(개인정보보호법)에 의거하고 원칙과 규정을 수립하고 이를 준수해야 한다.</li>
							  <li className="small" data-custom="②">개인정보의 안전성 확보에 필요한 조치를 하는 경우 해당유형 및 정보 보유량에 따른 안전조치 기준을 적용해야 하며 내부 관리계획의 수립 및 시행, 접근권리의 관리, 접근통제, 암호화 등에 관한 관리기준을 정해야 한다.</li>
							  <li className="small" data-custom="③">이름, 주민등록번호 또는 이를 유추할 수 있는 정보를 회사에서 취급하는 경우, 정보보호 책임자를 임명해야 하며, 정보보호 책임자는 정보보호계획 수립, 정보보호 교육, 내부통제시스템 구축, 자료 관리 감독 등의 업무를 수행해야 한다.</li>
							  <li className="small" data-custom="④">개인정보처리자는 개인정보가 분실.도난.유출.위조.변조 또는 훼손되지 않도록 내부 관리 계획 수립, 접속기록 보관 등 법으로 정하는 바에 따라 안정성 확보에 필요한 기술적.관리적 조치를 해야 한다.</li>
							</ul>
						  </div>
						</div>
					</div>					
								
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">6. 개인정보 유출 통지 및 신고제 도입</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">
								개인정보의 유출<br />
								  - 이해관계자들과 관련된 정보가 권한이 없는 자에게 전달되거나 저장 매체를 분실 혹은 도난 당한 경우를 의미한다.<br />

								- 회사는 개인정보 유출을 알게 된 이후 5일 이내에 이를 이해관계자들에게 알려야 한다.<br />

								다만, 유출된 개인정보의 확산 및 추가 유출을 방지하기 위해 접속경로의 차단, 취약점 점검.보완, 유출된 개인정보의 삭제 등 긴급한 조치가 필요한 경우 조치를 한 후 5일 이내에 알릴 수 있다.<br />

								- 이해관계자들에게 유출 사항을 통지할 경우 서면, 전자우편, 모사전송, 전화, 휴대전화 문자전송 등과 같은 방법을 통해 알려야 한다.
								
							  </li>
								<li className="small" data-custom="②">
								 개인정보 유출 사고 신고<br />
								  - 1만명 이상의 이해관계자들에 관한 개인정보를 처리하는 사업주의 경우 개인정보 유출사고 대응 매뉴얼을 마련해야 한다
									<div className="table_box c_b">
										<table className="line">
											<colgroup>
												<col></col>
												<col></col>
												<col></col>
											</colgroup>
											<thead>
												<tr>
													<th>한국인터넷진흥원</th>
													<td>인터넷 보호나라</td>
													<td>해킹, 악성 프로그램, 피싱 등 침해사고 발생 시 상담 및 신고</td>
												</tr>
												<tr>
													<th>중소벤처기업부</th>
													<td>기술보호울타리</td>
													<td>사전 예방과 사후 피해구제 가능(기술보호에 중점)</td>
												</tr>
												<tr>
													<th>수사기관</th>
													<td colSpan="2">경찰서 사이버수사팀, 시도 지방경찰청 사이버수사대</td>
												</tr>
											</thead>
										</table>
									</div>
								
							  </li>
							  
							</ul>
						  </div>
						</div>
					</div>					
					
					<h2 className="h3 py-5 mt-5 pb-lg-5 pb-md-0 pb-sm-2">II. 개인정보보호 방침</h2>
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">1. 개인정보의 수집항목, 수집.이용목적 및 보유기간</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">”개인정보”란 살아 있는 개인에 관한 정보로서 다음 각 목에 해당하는 정보를 말한다.<br />
								  가) 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보<br />

									나) 해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보. 이 경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데 소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 한다.
							  </li>
							  <li className="small" data-custom="②">“정보주체” 란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말한다.</li>
							  <li className="small" data-custom="③"> “처리” 란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 , 그 밖에 이와 유사한 행위를 말한다.</li>
								<li className="small" data-custom="④">“개인정보처리자” 란 업무를 목적으로 개인정보파일을 운용하기 위해 스스로 또는 다른 사람을 통해 개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다.
								
								<div className="table_box c_b wb_ka">
									<table className="line">
										<colgroup>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
										</colgroup>
										<thead>
											<tr className="text-center">
												<th>개인정보 항목</th>
												<th>수집·이용 목적</th>
												<th>보유기간</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>가. 성명<br />
												나. 주소, 이메일, 연락처<br />
												다. 학력, 근무경력, 자격증<br />
												라. 세금 감면 및 군경력<br />
												마. 기타 근무와 관련된 개인정보</td>
												<td>가. 채용 및 승진 등 인사관리<br />
												나. 세법, 노동관계법령 등에서 부과하는 의무이행<br />
												다. 급여, 복리후생, 4대보험 업무
												</td>
												<td>재직기간 동안 보유하고, 기타 개별법령에서 보유기간을 정하고 있는 경우 그에 따름</td>
											</tr>
										</tbody>
									</table>
								</div>
								</li>
								<li>
								<div className="table_box c_b wb_ka">
									<table className="line">
										<colgroup>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
										</colgroup>
										<thead>
											<tr className="text-center">
												<th>민감정보의 항목</th>
												<th>수집·이용 목적</th>
												<th>보유기간</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>가. 신체장애<br />
												나. 병력<br />
												다. 범죄<br />
												라. 지문</td>
												<td>가. 채용 및 승진 등 인사관리<br />
												나. 세법, 노동관계법령 등에서 부과하는 의무이행<br />
												다. 정부지원금 신청<br />
												라. 사무실 출입
												</td>
												<td>재직기간 동안 보유하고, 기타 개별법령에서 보유기간을 정하고 있는 경우 그에 따름</td>
											</tr>
										</tbody>
									</table>
								</div>
								</li>
								<li>
								<div className="table_box c_b wb_ka">
									<table className="line">
										<colgroup>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
											<col style={{width : "33.3333%"}}></col>
										</colgroup>
										<thead>
											<tr className="text-center">
												<th>고유식별정보</th>
												<th>수집·이용 목적</th>
												<th>보유기간</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>가. 주민등록번호<br />
												나. 운전면허번호<br />
												다. 여권번호<br />
												라. 외국인등록번호</td>
												<td>가. 채용 및 승진 등 인사관리<br />
												나. 세법, 노동관계법령 등에서 부과하는 의무이행<br />
												다. 정부지원금 신청
												</td>
												<td>재직기간 동안 보유하고, 기타 개별법령에서 보유기간을 정하고 있는 경우 그에 따름</td>
											</tr>
										</tbody>
									</table>
								</div>
								</li>
							</ul>
						  </div>
						</div>
					</div>					
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">2. 수집한 개인정보의 제3자 제공에 관한 사항</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">회사는 정보주체의 동의, 법률으 특별한 규정 등 [개인정보보호법] 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br />
								- 회사는 개인정보의 처리 및 이용목적에서 고지한 범위한 내에서 사용하며, 초과하여 이용할 때에는 정보주체로부터 별도의 동의를 받아 공개할 수 있습니다.<br />

								- 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보 주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br />

								- 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
							  </li>
							  <li className="small" data-custom="②">회사는 개인정보를 제3자에게 제공하는 경우 (개인정보를 제공받는 자, 개인정보의 이용 목적, 이용 또는 제공하는 개인정보의 항목, 개인정보의 보유 및 이용기간을 알린 후 동의를 받겠습니다.</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">3. 개인정보의 파기절차 및 파기방법</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">파기 계획 수립</li>
							  <li className="small" data-custom="②">파기절차 및 기한 : 보유기관이 경과했거나 처리목적이 달성된 후 5일이내 지체없이 파기</li>
							  <li className="small" data-custom="③">파기 방법<br />
							· 전자적 파일 : 복원이 불가능한 방법으로 영구 삭제<br />
							· 인쇄물, 서면, 그밖의 기록매체 : 파쇄 또는 소각</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">4. 정보주체와 법정대리인의 권리.의무 및 그 행사방법에 관한 사항</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">정보주체는 회사에 언제든지 개인정보 열람.정정. 삭제. 처리정지 요구 등의 권리를 행사할 수 있다.</li>
							  <li className="small" data-custom="②">권리행사는 [개인정보보호법] 시행령 제41조 제1항에 따라 서면, 전자우편, 팩스 등을 통하여 할 수 있다.</li>
							  <li className="small" data-custom="③">권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 할 수도 있다. 이 경우 위임장을 제출하여야 한다.</li>
								<li className="small" data-custom="④">개인정보 열람 및 처리정지 요구는 [개인정보보호법] 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있다.</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">5. 개인정보 유출 관리</h3>
						  <p>회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 기술적/관리적 대책을 강구한다.</p>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">기술적 보호 조치<br />
							- 회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석 시스템 등을 이용하여 보안에 만전을 기한다.<br />
							- 클라우드 서비스를 통해 안전성을 확보한다.</li>
							  <li className="small" data-custom="②"> 관리적 보호 조치<br />
								- 회사는 이용자의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한한다.<br />
								- 인사이동 또는 퇴직 등이 발생한 경우 접근 권한 변경 또는 권한을 삭제 한다.<br />
								- 개인정보가 포함된 서류 및 저장매체는 별도 분리하여 잠금장치가 있는 곳에 보관한다.<br />
								- 모든 임직원 보안서약서 징구를 통해 사람에 의한 정보유출을 사전에 방지한다.<br />
								- 개인정보 취급자의 지정을 최소화하고 정기적인 교육을 시행한다.</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">6. 개인정보보호 관리 역할 및 위원회 구성</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">개인정보보호 책임자는 사업주(대표이사)로 하고, 경영지원실(관리팀)을 담당부서로 하여 담당자를 지정 운영한다.</li>
							  <li className="small" data-custom="②">임직원을 포함한 이해관계자는 개인정보보호 담당 부서에 개인정보 유출과 관련된 사안을 상담하거나 제보할 수 있다.</li>
							  <li className="small" data-custom="③">개인정보보호 위원회 구성 : 위원장(대표이사), 위원(임원), 간사(관리팀)</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">7. 교육 훈련</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">목적 : 개인정보보호의 중요성을 인식하고 교육을 통해 법 위반 및 개인정보 유출 사고를 예방하기 위해 정기적으로 교육을 실시한다.</li>
							  <li className="small" data-custom="②">교육 대상 : 위피아 전임직원 (계약직 및 프리랜서 포함)</li>
							  <li className="small" data-custom="③">교육 방법 및 시기<br />
								- 교육 방법 : 온라인 과정 이수<br />
								- 교육 시기 : 연1회 (매1분기 이내)</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">8. 개인정보보호 서약서 징구</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">징구 대상 : 회사 임직원 (계약직 및 프리랜서, 위탁업체 포함)</li>
							  <li className="small" data-custom="②">징구 시기 : 재직자 연1회 징구 (1분기 내) , 신규 채용시 즉시 징구</li>
							  <li className="small" data-custom="③">징구 방법 : 그룹웨어 내 양식 게시-전자결재로 제출</li>
							</ul>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">9. 정기점검</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="small" data-custom="①">개인정보의 유출 위험 및 취약성을 점검하여 문제점 도출 및 개선대책을마련하여 법적 Risk를 제거 혹은 최소화하기 위해 실시한다.</li>
							  <li className="small" data-custom="②">정기점검 : 연1회 (3분기 이내)<br />
								* 불시점검 : 취약점이 발견되거나 조치 이행상황 확인이 필요한 경우 실시</li>
							  <li className="small" data-custom="③">점검사항<br />
							- 개인 업무 공간내 개인정보 보유 및 관리 상태 (시건장치 등)<br />
							- 서버 접속 권한 관리 (암호 설정 등)<br />
							- 인사 정보 권한 관리<br />
							- 개인 PC 자리비움시 암호 설정 상태</li>
							</ul>
						  </div>
						</div>
					</div>
					
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">10. 개인정보보호책임자 및 담당자</h3>
						<div className="txt-box">
						  <p>개인정보보호 관련 문의, 불만처리, 피해구제 등 문의</p>
						  <div className="table_box c_b">
							<table className="line">
							  <colgroup>
								<col></col>
								<col style={{width: "29%"}}></col>
								<col></col>
								<col></col>
							  </colgroup>
							  <thead>
								<tr className="text-center">
								  <th>구분</th>
								  <th>담당부서</th>
								  <th>성명</th>
								  <th>이메일</th>
								</tr>
							  </thead>
							  <tbody>
							  <tr className="text-center">
								<td>개인정보보호책임자</td>
								<td className="t_left">대표이사실</td>
								<td>김용연 사장</td>
								<td>info@wipia.co.kr</td>
							  </tr>
							  <tr className="text-center">
								<td>개인정보보호 및 고충처리 담당자</td>
								<td className="t_left">경영지원실(관리팀)</td>
								<td>최창미 부장</td>
								<td>info@wipia.co.kr<br />
								전화 : 02-2038-8311<br />
								팩스 : 02-2038-8322</td>
							  </tr>
							  </tbody>
							</table>
						  </div>
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">11. 정보주체의 권익침해에 대한 구제방법</h3>
						<div className="txt-box">
						  <p>회사는 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 기술적/관리적 대책을 강구한다.</p>
							<div className="table_box c_b">
								<table className="line">
									<colgroup>
										<col></col>
										<col></col>
										<col></col>
									</colgroup>
									<thead>
										<tr>
											<th>한국인터넷진흥원</th>
											<td>인터넷 보호나라</td>
											<td>해킹, 악성 프로그램, 피싱 등 침해사고 발생 시 상담 및 신고</td>
										</tr>
										<tr>
											<th>중소벤처기업부</th>
											<td>기술보호울타리</td>
											<td>사전 예방과 사후 피해구제 가능(기술보호에 중점)</td>
										</tr>
										<tr>
											<th>개인정보분쟁조정위원회</th>
											<td>국번없이 1833-6972</td>
											<td><a href="www.kopico.go.kr" target="_blank">www.kopico.go.kr</a></td>
										</tr>
										<tr>
											<th>개인정보침해신고센터</th>
											<td>국번없이 118</td>
											<td><a href="Privacy.kisa.or.kr" target="_blank">Privacy.kisa.or.kr</a></td>
										</tr>
										<tr>
											<th>대검철창</th>
											<td>국번없이 1301</td>
											<td><a href="www.spo.go.kr" target="_blank">www.spo.go.kr</a></td>
										</tr>
										<tr>
											<th>경찰청</th>
											<td>국번없이 182</td>
											<td><a href="ecrm.cyger.go.kr" target="_blank">ecrm.cyger.go.kr</a></td>
										</tr>
									</thead>
								</table>
							</div>
						  
						</div>
					</div>
					
					<div className="policy-sec">
					  <h3 className="sub_tit mt_0">12. 신고 절차</h3>
						<div className="txt-box">
						  <div className="group">
							<ul className="list_custom">
							  <li className="bold" data-custom="1.">
								<b>신고 접수</b>
								<p>E-mail, 전화, 서면 혹은 홈페이지를 통해 개인정보보호 담당 부서에 신고<br />
								· E-mail : info@wipia.co.kr, 전화 : 02-2038-8311<br />
								· 홈페이지 : www.wipia.co.kr</p>
							  </li>
							  <li className="bold" data-custom="2.">
								<b>신고시 필수 내용</b>
								<p>신고자실명, 이메일주소, 연락처, 제목, 내용, 증빙첨부(내용의 정확성을 위해 제보자 실명만을 원칙으로 함)</p>
							  </li>
							  <li className="bold" data-custom="3.">
								<b>신고 내용 조사</b>
								<p>개인정보보호 담담 부서에서 긴급 조사(불명확한 내용은 신고자에게 재확인)</p>
							  </li>
								<li className="bold" data-custom="4.">
								<b>조사 결과 심의</b>
								<p>개인정보보호위원회를 열어 재발 방지 대책 논의 및 징계 규정에 따라 조치<br />(내용의 정확성을 위해 제보자 실명만을 원칙으로 함)</p>
							  </li>
								<li className="bold" data-custom="5.">
								<b>이후 모니터링</b>
								<p>불시점검을 통해 개선 이행 여부를 확인하고, 재적발시 개인정보보호위원회를 열어 해당 업무 배제 등 징계 수위를 재결정함</p>
							  </li>
							</ul>
						  </div>
						</div>
					</div>
					
				  </article>
			</section>	
		</main>
	<Footer />
    </>);
}

export default Privacy;