import React, { useState, useEffect, useContext } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import moment from 'moment';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import instance from "../../axios_interceptor.js";
import Header from "../Component/Header";
import Button from 'react-bootstrap/Button';
import AdminHeader from "../Component/AdminHeader.jsx";
import AdminFooter from "../Component/AdminFooter.jsx";

import mainBg from "../../assets/img/bg-privacy.jpg";

function ComHisPrivWrite() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 네비게이터로 넘어온 데이터 받기 위한 훅
    const location = useLocation();

    // useLocation 사용하여 네비게이터로 넘어온 데이터 꺼내기
    const gubun = location.state.gubun;
    const data = location.state.data;

    const [projectNm, setProjectNm] = useState("");
    const [selDate, setSelDate] = useState(new Date());
    const [pGubun, setGubun] = useState(gubun); 

    // 로딩될때 최초 한번만
    useEffect(()=>{
        // 기존데이터가 있는 경우
        if(data!=null && data!="") {
            setSelDate(new Date(data.year+"-"+data.month));
            setProjectNm(data.taskContent);
        }
    }, []);

    // 프로젝트 명 값 set
    const handleChangeProjectNm = (e) => {
        setProjectNm(e.target.value);
    }

    // 작업 액션 삭제 set
    const handleChangeGubun = () => {
        if(window.confirm("삭제하시겠습니까?")){
            setGubun("delete");
        }
    }

    // pGubun 변경시 마다
    useEffect(()=>{
        if(pGubun=="delete") {
            handleChangeComYear();
        }
    }, [pGubun]);

    // 회사 연혁 등록, 수정
    const handleChangeComYear = () => {
        let param = {};
        
        // 작업 액션 확인
        if(pGubun=="insert") {
            param = {
                gubun : pGubun,
                year : moment(selDate).format("YYYY"),
                month : moment(selDate).format("M"),
                taskContent : projectNm
            };
        } else if(pGubun=="update") {
            param = {
                gubun : pGubun,
                year : moment(selDate).format("YYYY"),
                month : moment(selDate).format("M"),
                taskContent : projectNm,
                taskNo : data.taskNo
            };
        } else if(pGubun=="delete") {
            param = {
                gubun : pGubun,
                taskNo : data.taskNo 
            };
        } else {
            alert("작업이 유효하지 않습니다.");
            return;
        }

        // 삭제가 아닌 경우에만
        if(pGubun!="delete") {
            // 년월 확인
            if(selDate==null || selDate=="") {
                alert("년월을 선택해 주십시오.");
                return;
            }

            // 프로젝트 명 확인
            if(projectNm==null || projectNm=="") {
                alert("프로젝트 명을 입력해 주십시오.");
                return;
            }
        }

        const response = instance.post("/admin/changeYear", param, {headers: { "Content-Type": "application/json"}});
        response.then((res) => {
            if(res.data.rtnCd=="SUCCESS") {
                if(window.confirm(res.data.rtnMsg)) navigate("/comHisPrivList");
            } else if(res.data.rtnCd=="EXPIRE") {
                if(window.confirm("로그인 세션이 만료되었습니다.")) navigate("/adminLogin");
            } else {
                alert("관리자에게 문의바랍니다.");
            }
        });
    }

    const handleCancel = (e) => {
        navigate(-1);
    }

    return (<>
        {/* <div>
            <Header/>
            <br/>
            관리자가 접근 가능한 회사 연혁 등록 화면입니다.
            <br/>
            프로젝트 년월 : 
            <DatePicker 
                showIcon
                selected={selDate} 
                onChange={date => setSelDate(date)} 
                locale={ko} 
                dateFormat={"yyyy년 MM월"}
                showMonthYearPicker
            />
            <br/>
            프로젝트 명 : <textarea value={projectNm} onChange={handleChangeProjectNm}/>
            <br/>
            <Button onClick={handleChangeComYear}>저장</Button>{" "}
            {pGubun!="insert" && <Button onClick={handleChangeGubun}>삭제</Button>}
        </div> */}


        <main className="page-wrapper">
            <AdminHeader />

            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/comHisPrivList">Company</Link>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">회사연혁 등록</li>
				</ol>
			</nav>
			
			<section className="container pt-md-4 pb-md-5 mb-6">
				<h2 className="h2 mb-5 py-xl-3 pb-lg-2 pb-md-2 pb-sm-2">회사연혁 등록</h2>
					{/* <!-- 테이블 -->	 */}
				<div className="policy-sec">
					<div className="table_box c_b">
						<table className="line">
							<colgroup>
								<col width="20%" />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th>프로젝트 등록일</th>
									<td className="d-flex ">
                                    <DatePicker 
                                        showIcon
                                        selected={selDate} 
                                        onChange={date => setSelDate(date)} 
                                        locale={ko} 
                                        dateFormat={"yyyy년 MM월"}
                                        showMonthYearPicker
                                    />
                                        {/* <input type="text" className="form-control" id="dateFormat" data-format='{"date": true, "delimiter": "-", "datePattern": ["Y", "m", "d"]}' placeholder="2024-02-08" style={{width: "160px"}} onChange={date => setSelDate(date)} value={selDate}/>
                                        <button className="btn btn-outline-secondary btn-icon my-1 me-2 ms-2" type="button" aria-label="Confirm">
                                            <i className="bx bxs-calendar"></i>
                                        </button> */}
                                    </td>
								</tr>
								<tr>
									<th>프로젝트 명&nbsp;</th>
									<td> <textarea id="message" className="form-control" rows="4" required value={projectNm} onChange={handleChangeProjectNm}></textarea></td>
								</tr>									
							</thead>
						</table>
					</div>
				</div>
				<div className="row align-items-end gy-3 mt-1 pb-lg-3 pb-1 float-end">
                <button type="button" className="btn btn-outline-primary fs-sm me-2" style={{width: "120px"}} onClick={handleCancel}>취소</button>
                {pGubun!="insert" && <button type="button" className="btn btn-outline-primary fs-sm me-2" style={{width: "120px"}} onClick={handleChangeGubun}>삭제</button>}
                {/* <button type="button" className="btn btn-outline-primary fs-sm me-2" style={{width: "120px"}} onClick={handleChangeGubun}>삭제</button> */}
				<button type="button" className="btn btn-primary fs-sm me-3" style={{width: "120px"}} onClick={handleChangeComYear} >저장</button>
				</div>
				
			</section>	

        </main>
        <AdminFooter />

</>
    )
}

export default ComHisPrivWrite;