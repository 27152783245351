import { useEffect } from "react";

function LoadExternalJS() {
  useEffect(() => {
    const scriptArr = document.querySelectorAll("script");
    
    // 필요한 파일들을 동적으로 생성해줍니다.
    const scriptJquery1 = document.createElement("script");
    //scriptJquery1.src = "../assets/vendor/jarallax/dist/jarallax.min.js";
    scriptJquery1.src = "vendor/jarallax/dist/jarallax.min.js";
    scriptJquery1.async = true;

    const scriptJquery2 = document.createElement("script");
    //scriptJquery2.src = "../assets/vendor/rellax/rellax.min.js";
    scriptJquery2.src = "vendor/rellax/rellax.min.js";
    scriptJquery2.async = true;

    const scriptJquery3 = document.createElement("script");
    //scriptJquery3.src = "../assets/vendor/swiper/swiper-bundle.min.js";
    scriptJquery3.src = "vendor/swiper/swiper-bundle.min.js";
    scriptJquery3.async = true;

    const scriptJquery4 = document.createElement("script");
    scriptJquery4.src = "js/theme.min.js";
    scriptJquery4.async = true;

    // 생성된 script 요소들을 body에 붙여주세요
    document.body.appendChild(scriptJquery3);
    document.body.appendChild(scriptJquery1);
    document.body.appendChild(scriptJquery2);
    document.body.appendChild(scriptJquery4);

    return () => {
      document.body.removeChild(scriptJquery3);
      document.body.removeChild(scriptJquery1);
      document.body.removeChild(scriptJquery2);
      document.body.removeChild(scriptJquery4);
    };
  }, []);
  }
  
  export default LoadExternalJS;