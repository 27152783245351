import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import NaverMap from "./NaverMap";
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import contactBg from "../../assets/img/bg-contact.jpg";
import chatImg from "../../assets/img/icons/chat.svg";
import faqImg from "../../assets/img/icons/faq.svg";
import emailImg from "../../assets/img/icons/email.svg";

function LocationInfo() {
    const [locationFlag, setLocationFlag]  = useState(true);

	// 영등포 37.5149  126.899
	// 가산   37.4823  126.876
    const fn_locationViewChange = (e) => {
		setLocationFlag(!locationFlag);

    }

    return (
        // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button name="Y" onClick={(e)=>{fn_locationViewChange(e)}}>영등포</Button>
        //         <Button name="G" onClick={(e)=>{fn_locationViewChange(e)}}>가산</Button>
        //     </ButtonGroup>
        //     <br/>
        //     {locationFlag=="Y"?"영등포":"가산"} 위치 화면입니다.
        // </div>
        <>
		<main className="page-wrapper">
			<Header />
			
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+contactBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">Contact Us</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA에 방문을 요청하시면 전문가들이 상담을 해드립니다. </p>
						</div>
					</div>

				</div>
			</section>

            {/* <!-- Breadcrumb --> */}
			<nav className="container pt-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  {/* <!--<li className="breadcrumb-item"> Business</li>--> */}
				  <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
				</ol>
			</nav>

			{/* <!-- Tab Menu --> */}
			<section className="container">
				<div className="tab-pane fade show active" role="tabpanel">
					<ul className="nav nav-tabs" role="tablist">
						<li className="nav-item ">
						  <a className={locationFlag? "nav-link active d-block text-center" : "nav-link d-block text-center"} onClick={(e)=>{fn_locationViewChange(e)}} href="#" data-bs-toggle="tab" role="tab" style={{width: '9rem'}}>영등포 본사</a>
						</li>
						<li className="nav-item">
						  <a className={!locationFlag? "nav-link active d-block text-center" : "nav-link d-block text-center"} onClick={(e)=>{fn_locationViewChange(e)}} href="#" data-bs-toggle="tab" role="tab" style={{width: '9rem'}}>가산지사</a>
						</li>
					</ul>
				  
					<div className="tab-content mt-n1">
						
						{/* <!-- 영등포 본사 --> */}
						<div className={locationFlag? "tab-pane fade show active" : "tab-pane fade" } id="map1" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3  mb-2 ">
									<div className="pe-xl-5 me-md-2 me-lg-4 mt-4">
										  <h2 className=" mb-3 mb-sm-4">찾아오시는 길</h2>
										  <p className="mb-3 mb-sm-4">서울특별시 영등포구 경인로 775, 1동 507호 (문래동3가, 에이스하이테크시티)  </p>
									</div>
								</div>		
								<div className="row">
									<div className="mb-5 mb-lg-0">
										<div className="d-flex flex-column h-100 overflow-hidden">
										  {locationFlag && <NaverMap let={37.5149} lng={126.899} />}
										</div>
									</div>	
								</div>
									
								<div className="row m-auto">
									<div className="bg-secondary py-5 px-3 px-sm-6">
										<div className="row row-cols-1 row-cols-sm-3 pt-sm-3 py-2 m-sm-auto">
											<div className="col d-flex d-sm-block d-lg-flex align-items-start">
												<div className="bg-primary rounded p-2 ms-3">
													<img src={chatImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">Call Us</h3>
												  <p className="mb-1 mb-md-2 fs-4 ">02)2038-8311</p>
												</div>
											</div>
											<div className="col d-flex d-sm-block d-lg-flex align-items-start">
												<div className="bg-primary rounded p-2 ms-3">
												  <img src={faqImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">FAX</h3>
												  <p className="mb-1 mb-md-2 fs-4" >02)2038-8322</p>
												</div>
											</div>
											<div className="col d-flex d-sm-block d-lg-flex align-items-start">
												<div className="bg-primary rounded p-2 ms-3">
													<img src={emailImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">Email</h3>
												  <p className="mb-1 mb-md-2 fs-4">info@wipia.co.kr</p>
												</div>
											</div>
										</div>
									</div>
								</div>
											
								<div className="row py-5 mb-5">
									<div className="col-lg-3 text-lg-start">
										<span className="main-core-tit">교통편 안내</span>
									</div>
									<div className="col-xl-8 col-lg-8">
										<div className="row row-cols-1 row-cols-md-2">
											<div className="col">
												<div className="card-body mb-6 align-items-start">						
													<div>
														<span className="line-fill"></span>
														<span className="main-core-tit">지하철</span>
														<div><span className="subway" style={{background:'#3ab449'}}>2</span>문래역 6번 출구(도보 5분)</div>
														<div className="mt-2"><span className="subway" style={{background:'#263c96'}}>1</span>영등포역 6번 출구(도보 8분)</div>
													</div>
												</div>
											</div>
											<div className="col">
												<div className="card-body mb-6 align-items-start">						
													<div>
														<span className="line-fill"></span>
														<span className="main-core-tit">버스<span className="fs-6">(영등포등기소,구로세무서 정류장에서 하차)</span></span>
												  		<div>간선버스 : 503, 670, 260, 160, 600</div>
														<div className="mt-2">지선버스 : 5614, 6512, 6513, 6637, 6640, 5615</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
						
						
						{/* <!-- 가산 지사 --> */}
						<div className={!locationFlag? "tab-pane fade show active" : "tab-pane fade" }  id="map2" role="tabpanel">					
							{/* <!-- Image + Text sections --> */}
							<section className="container pb-sm-1 pb-md-3 p-2">
								{/* <!-- Row 1 --> */}
								<div className="row align-items-lg-center pt-md-3  mb-2 ">
									<div className="pe-xl-5 me-md-2 me-lg-4 mt-4">
										  <h2 className=" mb-3 mb-sm-4">찾아오시는 길</h2>
										  <p className="mb-3 mb-sm-4">서울특별시 금천구 가산디지털2로 169-23, 가산모비우스타워 1401-2,3호</p>
									</div>
								</div>		
								<div className="row">
									<div className="mb-5 mb-lg-0">
										<div className="d-flex flex-column h-100 overflow-hidden">
										  {!locationFlag && <NaverMap let={37.48229292437237} lng={126.87679535188254} /> }
										</div>
									</div>	
								</div>
									
								<div className="row m-auto">
									<div className="bg-secondary py-3 px-3 px-sm-6">
										<div className="row row-cols-1 row-cols-sm-3 pt-sm-3 py-2 m-sm-auto">
											<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
												<div className="bg-primary rounded p-2 ms-3">
												  <img src={chatImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">Call Us</h3>
												  <p className="mb-1 mb-md-2 fs-4 ">02)2038-8311</p>
												</div>
											</div>
											<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
												<div className="bg-primary rounded p-2 ms-3">
												  <img src={faqImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">FAX</h3>
												  <p className="mb-1 mb-md-2 fs-4" >02)2038-8322</p>
												</div>
											</div>
											<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
												<div className="bg-primary rounded p-2 ms-3">
													<img src={emailImg} width="24" className="d-block m-1" alt="Icon" />
												</div>
												<div className="ps-3 ps-lg-4">
												  <h3 className="h4 mb-2 mb-md-3">Email</h3>
												  <p className="mb-1 mb-md-2 fs-4">info@wipia.co.kr</p>
												</div>
											</div>
										</div>
									</div>
								</div>
											
								<div className="row py-5">
									<div className="col-lg-3 text-lg-start">
										<span className="main-core-tit">교통편 안내</span>
									</div>
									<div className="col-xl-8 col-lg-8">
										<div className="row row-cols-1 row-cols-md-2">
											<div className="col">
												<div className="card-body mb-6 align-items-start">						
													<div>
														<span className="line-fill"></span>
														<span className="main-core-tit">지하철</span>
														<div><span className="subway" style={{background:'#697215'}}>7</span>가산디지털단지역 7,9번 출구 (도보 10분)</div>
														{/* <!--<div className="mt-2"><span className="subway" style="background:#263c96">1</span>영등포역 6번 출구(도보 8분)</div>--> */}
													</div>
												</div>
											</div>
											<div className="col">
												<div className="card-body mb-6 align-items-start">						
													<div>
													<span className="line-fill"></span>
														<span className="main-core-tit">버스<span className="fs-6">(디지털3단지운동장 정류장에서 하차)</span></span>
														<div>일반버스 : 21</div>
												  		<div className="mt-2">간선버스 : 653</div>
														<div className="mt-2">지선버스 : 5012, 5528</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
						
					</div>
				</div>
			</section>
		</main>
	<Footer />
        </>
    )
}

export default LocationInfo;