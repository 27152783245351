import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-esg-09.jpg";

import esgImg01 from "../../assets/img/esg/fruitShop.jpg";
import esgImg02 from "../../assets/img/esg/A4Paper.png";
import esgImg03 from "../../assets/img/esg/envCleanup4.jpg";

function SocialContirb() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
		
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">ESG</li>
				  <li className="breadcrumb-item active" aria-current="page">사회공헌</li>
				</ol>
			</nav>
			
			<section className="container py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">사회공헌</h2>
					<div className="col-12">
						<p className="mb-0 h1-sub">위피아(IT기업)는 사회의 발전에 기여하는 핵심 주체로서, 사회적 책임을 다하는 기업시민으로서의 역할을 수행하며, 사회적 책임 실현을 통해 모두가 행복하고 더 나은 세상을 만들어가는 기업 문화를 조성합니다.</p>
					</div>
			</section>
			
			{/* <!-- 사회공헌 체계도 --> */}
			<section className="bg-secondary py-3  mb-5 my-2 my-md-4 px-3 px-sm-6">
				 <div className="container my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row ">
						<div className="mt-4 py-2 pb-sm-3 pb-md-4 pb-lg-5 mx-auto text-center">
							<h2 className="h1 mb-4">사회공헌 체계도</h2>
							<p className="mb-4">위피아는 사회공헌에 이바지하는 기업이 되기 위해 최선을 다합니다.</p>
						</div>

						{/* <!-- Steps --> */}
						<div className="steps steps-center steps-sm steps-horizontal-lg py-6" style={{siStepsNumberSizeSm: "1.5rem", siStepsNumberInnerSizeSm: "1rem", siStepsNumberBg: "rgba(99,102,241,.2)", siStepsNumberInnerBg: "#6366f1"}}>

							{/* <!-- Step --> */}
							<div className="step w-100 mx-auto">
								<div className="step-number"><div className="step-number-inner" style={{backgroundColor: "#0078bf"}}></div></div>
								<div className="step-body">
								  <h3 className="mb-3">비전</h3>
								  <p className="mb-0">모두가 행복한 세상을 만드는 기업</p>
								</div>
							</div>

							{/* <!-- Step --> */}
							<div className="step w-100 mx-auto" style={{maxWidth: "26rem"}}>
								<div className="step-number"><div className="step-number-inner" style={{backgroundColor: "#0078bf"}}></div></div>
								<div className="step-body">
								  <h3 className="mb-3">미션</h3>
								  <p className="mb-0">모두가 함께하는 지속가능한 사회를 만드는 기업</p>
								</div>
							</div>



							{/* <!-- Step --> */}
							<div className="step w-100 mx-auto">
								<div className="step-number"><div className="step-number-inner" style={{backgroundColor: "#0078bf"}}></div></div>
								<div className="step-body">
								  <h3 className="mb-3">슬로건</h3>
								  <p className="mb-0">함께 만들어가는 지속가능한 세상</p>
								</div>
							</div>							
						</div>
					</div>

					
					 {/* <!-- 추진전략 --> */}
					 <div className="row row-cols-1 row-cols-md-3  my-2 my-md-4 py-2">

					 	{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body py-sm-2 mb-3">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">추진전략 01</h2>
									<p className="mb-0 mt-3r">지속가능한 사회공헌 체계구축</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body py-sm-2 mb-3">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">추진전략 02</h2>
									<p className="mb-0 mt-3r">사회적 약자 배려와 존중의 문화 확산</p>
								</div>
							</div>
						</div>

						{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body py-sm-2 mb-3">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">추진전략 03</h2>
									<p className="mb-0 mt-3r">지역공동체와 연대 협력 강화 및 체계 고도화&nbsp;</p>
								</div>
							</div>
						</div>
						 
					</div>
				</div>			 
			</section>
			<section className="container py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">사회공헌 활동</h2>
					
			</section>
			{/* <!-- Item 01 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={esgImg01} alt="Image" />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">01</p>
						<h1 className="h2 mb-sm-4 con-left">지역 전통시장 활성화 참여</h1>
						{/* <p className="fs-5  con-left">지역 전통시장 활성화 참여</p> */}
					</div>
				</div>
			</section>

			{/* <!-- Item 02 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={esgImg02} alt="Image" />
						</div>
					</div>
					 <div className="col-md-6 mb-4">
						<p className="con-blue">02</p>
						<h1 className="h2 mb-sm-4">장애인기업 물품 구입</h1>
						{/* <p className="fs-5" style={{maxWidth: "26rem"}}>장애인기업 물품 구입</p> */}
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="container mb-7"> {/* 마지막 Item에만 mb-7 붙여주세요 */}
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={esgImg03} alt="Image" />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">03</p>
						<h1 className="h2 mb-sm-4 con-left">환경정화 활동</h1>
						{/* <p className="fs-5  con-left" style={{maxWidth: "28rem"}} >환경정화 활동</p> */}
							</div>
				</div>
			</section>

			{/* <!-- Item 04 --> */}
			{/* <section className="container mb-7">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons04} alt="Image" />
						</div>
					</div>
					<div className="col-md-6">
						<p className="con-blue">04</p>
						<h1 className="h2 mb-sm-4">Needs</h1>
						<p className="fs-5" style={{maxWidth: "28rem"}}>고객이 원하는 형태의 업무 시스템 구축, 유지보수 및 아우소싱등 다양한 상황을 반영하는 컨설팅을 제공합니다.</p>
					</div>
				</div>
			</section> */}
		</main>
	<Footer />
    
    </>
        
    )
}

export default SocialContirb;