import React from "react";
import chat from "../../assets/img/icons/chat.svg";
import faq from "../../assets/img/icons/faq.svg";
import email from "../../assets/img/icons/email.svg";

function CallWipia(props) {


    return (
        <section className="container mb-7">
				<div className="bg-secondary rounded-3 my-2 my-md-4 py-5 px-3 px-sm-6">
					<h1 className="h4 mb-sm-5 text-lg-center text-lg-start">WIPIA 의 전문가에게 직접 문의해 보세요.</h1>
					<div className="row row-cols-1 row-cols-sm-3 pt-sm-3 py-2 m-sm-auto">

						<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
							<div className="bg-primary rounded p-2 ms-3">
							  <img src={chat} width="24" className="d-block m-1" alt="Icon" />
							</div>
							<div className="ps-3 ps-lg-4">
							  <h3 className="h4 mb-2 mb-md-3">Call Us</h3>
							  <p className="mb-1 mb-md-2 fs-4 ">02)2038-8311</p>
							</div>
						</div>
						<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
							<div className="bg-primary rounded p-2 ms-3">
							  <img src={faq} width="24" className="d-block m-1" alt="Icon" />
							</div>
							<div className="ps-3 ps-lg-4">
							  <h3 className="h4 mb-2 mb-md-3">FAX</h3>
							  <p className="mb-1 mb-md-2 fs-4" >02)2038-8322</p>
							</div>
						</div>
						<div className="col d-flex d-sm-block d-lg-flex align-items-start mb-3">
							<div className="bg-primary rounded p-2 ms-3">
							  <img src={email} width="24" className="d-block m-1" alt="Icon" />
							</div>
							<div className="ps-3 ps-lg-4">
							  <h3 className="h4 mb-2 mb-md-3">Email</h3>
							  <p className="mb-1 mb-md-2 fs-4">info@wipia.co.kr</p>
							</div>
						</div>
					</div>
				</div>
			</section> 
    )
}


export default CallWipia;