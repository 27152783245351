import React, {useState, useEffect, useRef} from "react";


function NaverMap(props) {
    // 영등포 37.5149  126.899
	// 가산   37.4823  126.876
    const mapRef = useRef(null);
    const { naver } = window;
    
    useEffect(() => {
        
        if (mapRef.current && naver) {
          const location = new naver.maps.LatLng(props.let, props.lng);
          const map = new naver.maps.Map(mapRef.current, {
            center: location,
            zoom: 17, // 지도 확대 정도
          });
          new naver.maps.Marker({
            position: location,
            map,
          });
        }
  
      }, []);
    return (<div ref={mapRef} style={{ border: '1px solid #c8c8c8', minHeight: '500px' }}></div>);
}


export default NaverMap;