import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './Ui/Main/Main'; 
import AdminLogin from './Ui/Main/AdminLogin';
import ComOverview from './Ui/Company/ComOverview';
import CoreCompetence from './Ui/Company/CoreCompetence';
import ComHisPubList from './Ui/Company/ComHisPubList';
import ComHisPrivList from './Ui/Company/ComHisPrivList';
import ComHisPrivWrite from './Ui/Company/ComHisPrivWrite';
import EnviroMgmt from './Ui/Esg/EnviroMgmt';
import EthicalMgmt from './Ui/Esg/EthicalMgmt';
import SocialContirb from './Ui/Esg/SocialContirb';
import HumanRightMgmt from './Ui/Esg/HumanRightMgmt';
import SafetyMgmt from './Ui/Esg/SafetyMgmt';
import VisionObjective from './Ui/Esg/VisionObjective';
import HumanReport from './Ui/Esg/HumanReport';
import EthicalReport from './Ui/Esg/EthicalReport';
import ITConsulting from './Ui/Business/ITConsulting';
import SystemIntegration from './Ui/Business/SystemIntegration';
import Solution from './Ui/Business/Solution';
import ITService from './Ui/Business/ITService';
import ConstructionCase from './Ui/Business/ConstructionCase';
import LocationInfo from './Ui/ContactUs/LocationInfo';
import RecruitmentGuide from './Ui/Recruit/RecruitmentGuide';
import Privacy from './Ui/Main/Privacy';
import Welfare from './Ui/Recruit/Welfare';
import { CookiesProvider } from 'react-cookie';
import ArgProvider from './Context/ArgProvider';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import AuthRouter from './Router/AuthRouter';

// Main Theme Script
//import './assets/js/theme.min.js';

// Vendor Script
// import './assets/vendor/jarallax/dist/jarallax.min.js';
// import './assets/vendor/rellax/rellax.min.js';
// import './assets/vendor/swiper/swiper-bundle.min.js';



// css
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/theme.css";


function App(props) {
  return (<>
    
    {/* <script src="./assets/vendor/jarallax/dist/jarallax.min.js" type="text/javascript"></script>
    <script src="./assets/vendor/rellax/rellax.min.js" type="text/javascript"></script>
    <script src="./assets/vendor/swiper/swiper-bundle.min.js" type="text/javascript"></script>
    <script src="./assets/js/theme.min.js" type="text/javascript"></script> */}
    
    {/* <div className="page-loading active">
		  <div className="page-loading-inner">
			<div className="page-spinner"></div><span>Loading...</span>
		  </div>
		</div> */}
    
    {/* <!-- Navbar --> */}
			

    
    <CookiesProvider>
      <ArgProvider>
        <BrowserRouter>
          
		  
		<Routes>
            {/* 진입로 */}
            <Route index element={<Main />} />
            <Route path="/main" element={<Main />} />
            <Route path="/adminLogin" element={<AdminLogin />} />

            {/* 회사정보 */}
            <Route path="comOverview" element={<ComOverview />} />
            <Route path="coreCompetence" element={<CoreCompetence />} />
            <Route path="comHisPubList" element={<ComHisPubList />} />

            <Route path="comHisPrivList" element={<AuthRouter component={<ComHisPrivList/>}/>}/>
            {/* <Route path="comHisPrivList" element={<ComHisPrivList/>}/> */}
            <Route path="comHisPrivWrite" element={<AuthRouter component={<ComHisPrivWrite/>}/>}/>
            {/* <Route path="comHisPrivWrite" element={<ComHisPrivWrite/>} /> */}

            {/* ESG */}
            <Route path="enviroMgmt" element={<EnviroMgmt />} />
            <Route path="ethicalMgmt" element={<EthicalMgmt />} />
            <Route path="socialContirb" element={<SocialContirb />} />
            <Route path="humanRightMgmt" element={<HumanRightMgmt />} />
            <Route path="safetyMgmt" element={<SafetyMgmt />} />
            <Route path="visionObjective" element={<VisionObjective />} />
            <Route path="humanReport" element={<HumanReport />} />
            <Route path="ethicalReport" element={<EthicalReport />} />

            {/* 비즈니스 */}
            <Route path="iTConsulting" element={<ITConsulting />} />
			<Route path="systemIntegration" element={<SystemIntegration />} />
			<Route path="solution" element={<Solution />} />
			<Route path="iTService" element={<ITService />} />
			<Route path="constructionCase" element={<ConstructionCase />} />

            {/* 위치정보 */}
            <Route path="locationInfo" element={<LocationInfo />} />

            {/* 채용안내 */}
            <Route path="recruitmentGuide" element={<RecruitmentGuide />} />
            <Route path="recruit/welfare" element={<Welfare />} />

			<Route path="privacy" element={<Privacy />} />
		</Routes>
			

		
        </BrowserRouter>

		
      </ArgProvider>
    </CookiesProvider>
    
    </>
  );
}

export default App;
