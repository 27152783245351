import instance from "../axios_interceptor.js";
import axios from 'axios';

// 권한 체크
export const fn_authChek = () => {
    if(sessionStorage.getItem("loginCk")) {
        return true;
    } else {
        return false;
        
    }
}

// 파일업로드
export const fn_fileUpload = async (imgFile, flag) => {
    const fd = new FormData();

    for(let i = 0; i < imgFile.length; i++) {
        fd.append("addFilePhysical", imgFile[i]);
    }

    fd.append("gubun", flag);

    const response = instance.post("/admin/esgFileUpload", fd, {headers: {"Content-Type": "multipart/form-data"}});
    response.then((res) => {
        if(res.data.rtnCd=="SUCCESS") {
            alert(res.data.rtnMsg);
        } else if(res.data.rtnCd=="EXPIRE") {
            if(window.confirm("로그인 세션이 만료되었습니다.")) window.location.href = "/adminLogin";
        } else {
            alert("관리자에게 문의바랍니다.");
        }
    });
}

// 다운로드 파일 이름을 추출하는 함수
const fn_extractDownloadFilename = (response) => {
    const disposition = response.headers["content-disposition"];
    const fileName = decodeURI(
        disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );

    return fileName;
};

// 파일 다운로드
export const fn_fileDownload = async (flag) => {
    axios({
    url: "/esgFileDown", // 파일 다운로드 요청 URL
    method: "POST", // 혹은 'POST'
    responseType: "blob", // 응답 데이터 타입 정의
        params: {gubun : flag}
    }).then((response) => {
        // 성공시
        if(response.status==200) {
            // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
            const blob = new Blob([response.data]);
            // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다.
            // const blob = new Blob([this.content], {type: 'text/plain'})
            
            // blob을 사용해 객체 URL을 생성합니다.
            const fileObjectUrl = window.URL.createObjectURL(blob);
            
            // blob 객체 URL을 설정할 링크를 만듭니다.
            const link = document.createElement("a");
            link.href = fileObjectUrl;
            link.style.display = "none";
            
            // 다운로드 파일 이름을 지정 할 수 있습니다.
            // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
            link.download = fn_extractDownloadFilename(response);
            
            // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
            window.URL.revokeObjectURL(fileObjectUrl);
        } 
        // 실패시
        else {
            alert("관리자에게 문의 바랍니다.");
            return ;
        }
    });
}

// 메일발송
export const fn_send = async (imgFile, emailInfo, url, callBFunction) => {
    return new Promise((resolve, reject) => {

        const fd = new FormData();

        if(!fn_checkVaildateMail(emailInfo)){
            return ;
        }

        for(let i = 0; i < imgFile.length; i++) {
            fd.append("addFilePhysical", imgFile[i]);
        } 

        let emailInfoKeys = Object.keys(emailInfo);
        for(let i=0; i<emailInfoKeys.length; i++) {
            let key = emailInfoKeys[i];
            fd.append(emailInfoKeys[i], emailInfo[key]);

        }
        
        axios.post("/esgSendMail", fd, {
        headers: {
                    "Content-Type": `multipart/form-data; `
                }
        })
        .then((response) => {
            if(response.data.rtnCd=="SUCCESS") {
                resolve(alert(response.data.rtnMsg)); //메일발송에 성공했습니다.
                // window.location.href = url;
            } else {
                alert("관리자에게 문의바랍니다.");
            }
        callBFunction(false);
        })
        .catch((error) => {
        // 예외 처리
        	callBFunction(false);
            reject(error);
        });
    });
}


const fn_checkVaildateMail = (mailInfo) => {
    console.log(mailInfo);

    // 이메일
    const emailPattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;
    if(mailInfo.registMail.trim() == ""){
        alert("이메일을 입력해주세요");
        return false;
    }
    if(emailPattern.test(mailInfo.registMail) === false){
        alert("이메일을 확인하세요");
        return false;
    }

    //성명
    if(mailInfo.registName.trim() == ""){
        alert("제보자 성명을 입력해주세요");
        return false;
    }

    //연락처
    const phonePattern = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
    if(mailInfo.registTelNo.trim() == ""){
        alert("연락처를 입력해주세요");
        return false;
    }
    if(phonePattern.test(mailInfo.registTelNo) === false){
        alert("연락처를 확인하세요");
        return false;
    }

    //제목
    if(mailInfo.registTitle.trim() == ""){
        alert("제목을 입력해주세요");
        return false;
    }

    //내용
    if(mailInfo.registContent.trim() == ""){
        alert("내용을 입력해주세요");
        return false;
    }

    //요구사항
    if(mailInfo.registRequire.trim() == ""){
        alert("요구사항을 입력해주세요");
        return false;
    }

    return true;
}

/*
    내용 수정 필요 시 title contText
*/
export function createPopup(titleText , contentText) {
    const popupOverlay = document.createElement('div');
    popupOverlay.classList.add('popup_success', 'active');
  
    const popupWrap = document.createElement('div');
    popupWrap.classList.add('popup_wrap');
  
    const closeBtn = document.createElement('div');
    closeBtn.classList.add('close', 'x_btn');
  
    const titleArea = document.createElement('div');
    titleArea.classList.add('title_area');
  
    const icon = document.createElement('div');
    icon.classList.add('icon');
    const iconImg = document.createElement('img');
    iconImg.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA0BJREFUaEPtWUtS20AQfXLlCMHF0pwk9lKnAN9Cu5CdbgGcQkvgJNGSAo4QUNRCCqNR90zPR6GoQht7MR691/366wKf/Ck+OX58EfhoD2bzwGnV7F6BcwC7DtgVwI6+A2iJZAe0xdv39hW4f67LuxzkkwgYoC9GsCGYiMz1Brh5qMuBZMwTTWBbNT/7F17GvNT6zUDksS5/xdwVTOB71ew3wG3Myzy/iSISREBh9QEEafwb0JI0SGYE/M97XJwXwN5B5jLEG2oCJ1VzK7w42HKK2CFJHTVeVhFwgA+ylg3IIMLFkoqEl4AgG0qFx1ypcCRCcTXIzXi8BnISEAK2fazLM417Q85IJDrg+FSX19JdTgLbqvltWaXdAIeUvO0iJZBwGkwkwEmnzy6HXLKRiIwkyHDmI8aDi0AXqscQybjOnlTNRQFcGWdEz7MEtlVDP6b24N/T52ZvwIcQIEtLUhSkxAa0RGCmfV8ghQCns0ZyELMM5wUueUgEZvLJaX0ms7EkuFjgYnBBgGGuKigaL0h9VN+RnnFyYgroguyCgK3/XPKRwLsym23MDrh7qsuDaSzOA7OeJ0fqjAFvxcqAWUXALl6Se6cX+OpCLHi6n4mDRVHjJKQK4ElqLomlgF+VgCZOUsFPOu87AqdBOQ/MaoAtoTGwaJycdY6mJ3KBj5KQnbq4IB77pMUgTyRo+8CNnDHJwDaENojtNoItNBIJrh7EgKd7NDXJW8g41oY+SUrOlUos+JHALKVzCWNBQKM708ouT6SAp3fYAcyldLYXsuPAV405EqngNVWYSErNnL208o6RJolU8KP17WmQ7clYAqFTkRkTOfae3DQodQTikML14zk3EVL3KtQQcW4QCUgD9n8Y6u15GK55xDkmMl4gw62ymSCDvQBX9vbPF0/eOVdabOX0hGNhnLbYsgqWvf4L3olyupcWxq4C6hxouJd4dpjBRDz3sYOLFPReCTFVV/pTo6VGDsANfXLr9b7J+9Fbdv8h6/WJiNROa4Z6z5mohXGQByYAPgkEkgmWYHAMSIAmIgpZcFckAZ8ujPKAFOgvb/omnTv/Zk39ZzKbBwKlssrxbB5YBZ3i0i8CCiOteuQvEd40T5Q/VDEAAAAASUVORK5CYII=';
    icon.appendChild(iconImg);
  
    const title = document.createElement('h4');
    //title.textContent = '접수 완료';
    title.textContent = titleText;
    
  
    titleArea.appendChild(icon);
    titleArea.appendChild(title);
  
    const contArea = document.createElement('div');
    contArea.classList.add('cont_area');
  
    const contText = document.createElement('p');
    //contText.innerHTML = '4주 이내에 확인 및 조치하여<br />결과를 회신드리겠습니다.';
    contText.innerHTML = contentText
    
    const okBtn = document.createElement('button');
    okBtn.classList.add('ok_btn', 'close');
    okBtn.textContent = 'OK';
    
    contArea.appendChild(contText);
    contArea.appendChild(okBtn);
  
    popupWrap.appendChild(closeBtn);
    popupWrap.appendChild(titleArea);
    popupWrap.appendChild(contArea);
  
    popupOverlay.appendChild(popupWrap);
    
    document.body.appendChild(popupOverlay);
    
    closeBtn.onclick = () => popupOverlay.remove(); 
    okBtn.onclick = () => popupOverlay.remove(); 


    //return popupOverlay;
  }
  
