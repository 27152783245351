import React from "react";
import { Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";


import mainBg from "../../assets/img/main_bg.png";
import busi01 from '../../assets/img/business-01.jpg';
import busi02 from '../../assets/img/business-02.jpg';
import busi03 from '../../assets/img/business-03.jpg';
import busi04 from '../../assets/img/business-04.jpg';
//import "../../assets/vendor/swiper/swiper-bundle.min.js";
function Main() {
    // return (
    //     <>  
    //         {process.env.REACT_APP_API_URL}
    //         <Button as="input" type="button" value="회사정보" onClick={()=>{navigate("/com/comOverview")}}/>{' '}
    //         <Button as="input" type="button" value="ESG" onClick={()=>{navigate("/esg/visionObjective")}}/>{' '}
    //         <Button as="input" type="button" value="비즈니스" onClick={()=>{navigate("/busi/businessArea")}}/>{' '}
    //         <Button as="input" type="button" value="회사위치" onClick={()=>{navigate("/contactUs/locationInfo")}}/>{' '}
    //         <Button as="input" type="button" value="채용정보" onClick={()=>{navigate("/recruit/welfare")}}/>{' '}
    //     </>
    // )

    return (
        <>
	
	<main className="page-wrapper">
		<Header />
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark min-vh-100" data-jarallax data-speed="0.4" data-bs-theme="dark">
        		<span className="position-absolute top-0 start-0 w-100 h-100" style={{background: `radial-gradient("116.18% 118% at 50% 100%, rgba(99, 102, 241, 0.1) 0%, rgba(218, 70, 239, 0.05) 41.83%, rgba(241, 244, 253, 0.07) 82.52%")`}}></span>
				<div className="jarallax-img" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="min-vh-100 d-flex flex-column py-5">
        			<div className="container position-relative text-center zindex-5 pt-md-5 ">
        				<div className="row mt-6">
        					<div className="col-xl-10 mx-auto">
								<h1 className="display-1 mb-md-4 pb-3">Sustainability Management</h1>
								<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-1-sub" style={{maxWidth: '36.5rem'}}>
								  미래의 성과를 중시하고<br />지속가능성을 추구하는 경영
								</p>
								<Link to="/comOverview" className="btn btn-lg btn-primary shadow-primary">Our Business</Link>
        					</div>
        				</div>
        			</div>
        		</div>
			</section>

            {/* <!-- Our Business --> */}
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h1 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">Our Business</h2>
				<div className="col-12">
					<p className="mb-0 h1-sub mx-3 mb-6">WIPIA는 고객의 경쟁력과 가치창조 실현을 위하여 IT Consulting 및 LBS, Mobile, 물류, 환경모니터링 분야의 특화 솔루션 공급과 창의적 지식과 경쟁력으로 고객에게 최적화된 스마트 IT 서비스 기업입니다.</p>
				</div>

                {/* <Swiper className="tab-pane fade show active"
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={3}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide>slide 1</SwiperSlide>
                    <SwiperSlide>slide 2</SwiperSlide>
                    <SwiperSlide>slide 3</SwiperSlide>
                    <SwiperSlide>slide 4</SwiperSlide>
                </Swiper> */}

				<div className="tab-pane fade show active" id="preview3" role="tabpanel">
					<div className="position-relative">
                
						<div className="swiper mx-n2" data-swiper-options='{
						"slidesPerView": 1,
						"loop": true,
						"pagination": {
						  "el": ".swiper-pagination",
						  "clickable": true
						},
						"navigation": {
						  "prevEl": "#prev-news",
						  "nextEl": "#next-news"
						},
						"breakpoints": {
						  "500": {
							"slidesPerView": 2
						  },
						  "1600": {
							"slidesPerView": 3
						  }
						}
					  }'>
							<div className="swiper-wrapper">
								<div className="swiper-slide h-auto pb-3">
									<article className="card h-100 border-0 shadow-sm mx-4">
										<div className="position-relative">
											<Link to="/iTConsulting">
												<span className=" position-absolute top-0 start-0 zindex-5 mt-4 ms-6 fs-3 fw-semibold text-white" >
												  IT Consulting
												</span>
												<img src={busi01} className="card-img-top" />
											</Link>
										</div>
										<div className="card-body pb-4">                            
											<h3 className="h5 mb-0">
											<span className="line-fill"></span>
											축적된 경험과 노하우를 바탕으로 컨설팅을 제공하여 고객의 Needs에 부합한 e-Business 시스템을 구축합니다.
											검증된 솔루션을 바탕으로 LBS, Mobile, 물류 수.배송 분야를 전문으로하여 전략 컨설팅, 프로세스 혁신 컨설팅, 전략 실행 컨설팅 서비스를 제공합니다.
											</h3>
										</div>
                          
									</article>
								</div>
								<div className="swiper-slide h-auto pb-3">
									<article className="card h-100 border-0 shadow-sm mx-4">
										<div className="position-relative">
											<Link to="/systemIntegration">
												<span className="position-absolute top-0 start-0 zindex-5 mt-4 ms-6 fs-3 fw-semibold text-white" >
												  System Integration
												</span>
												<img src={busi02} className="card-img-top" />
											</Link>
										</div>
										<div className="card-body pb-4">
											<h3 className="h5 mb-0">
											<span className="line-fill"></span>
											맞춤형 시스템 개발, 기존시스템과의 통합개발, 유지보수, 시스템운영, 패키지 SW 기반의 신기술개발 적용 등으로
											고객의 사업목표를 효과적으로 지원할 수 있도록 웹 및 모바일 통합 IT 서비스를 제공합니다. 

											</h3>
										</div>                          
									</article>
								</div>
								<div className="swiper-slide h-auto pb-3">
									<article className="card h-100 border-0 shadow-sm mx-4">
										<div className="position-relative">
											<Link to="/solution">
												<span className=" position-absolute top-0 start-0 zindex-5 mt-4 ms-6 fs-3 fw-semibold text-white" >
												  Solution
												</span>
												<img src={busi03} className="card-img-top" />
											</Link>
										</div>
										<div className="card-body pb-4">
										   <h3 className="h5 mb-0">
											<span className="line-fill"></span>
											M2M(사물통신), LBS, Mobile, 환경모니터링, Embedded Test 분야에서 축적된 기술과 시스템구축 경험을 통해서 검증된 다양한 고객의 Needs를 충족시킬 수 있는 솔루션을 보유하고 있습니다.<br />
											새로운기술과 솔루션 개발을 위해 지속적인 연구와 개발을 진행하고 있습니다. 
											</h3>
										</div>                          
									</article>
								</div>
								<div className="swiper-slide h-auto pb-3">
									<article className="card h-100 border-0 shadow-sm mx-4">
										<div className="position-relative">
											<Link to="/iTService">
												<span className=" position-absolute top-0 start-0 zindex-5 mt-4 ms-6 fs-3 fw-semibold text-white" >
												  IT Service
												</span>
												<img src={busi04} className="card-img-top" />
											</Link>
										</div>
										<div className="card-body pb-4">                           
											<h3 className="h5 mb-0">
											<span className="line-fill"></span>
											LBS, GPS, Mobile, M2M, 전자지도 기반기술을 활용하여 IT 융합 및 스마트 서비스로 고객에게 최적화된 비즈니스 서비스를 지원 합니다.
											</h3>
 										</div>                          
									</article>
								</div>
							</div>
							<div className="swiper-pagination position-relative bottom-0 mt-4 mb-lg-2"></div>
						</div>
					</div> 


				</div>
			</section>


			{/* <!-- 핵심 경쟁력 --> */}
			<section className="bg-secondary py-5">
				<div className="position-relative container my-lg-5 my-md-4 my-3 py-xl-3">
					<div className="row">
						<div className="col-lg-4 text-lg-start pb-3 pb-lg-0 mb-4 mb-lg-0">
							<span className="main-core-line"></span>
							<h3 className="h3">Core Competencies</h3>
							<h3 className="h4 mb-4">핵심 경쟁력</h3>
							<p className="fw-medium mt-7">WIPIA의 핵심역량은 Greative한 사업기획력과<br />뛰어난 IT 기술력을 보유한 우수한 인력들 입니다.</p>
						</div>
						<div className="col-xl-7 col-lg-8">
							<div className="row row-cols-1 row-cols-md-2">
								<div className="col">
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">Public</p>
											<span className="main-core-tit">공공</span>
											<p className="fs-sm">교통정보제공, 버스정보시스템까지 ITS의 다양한 분야의<br />서비스제공</p>						  
										</div>
									</div>
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">Logistics</p>
											<span className="main-core-tit">물류</span>
											<p className="fs-sm">GPS, GIS, RFID 등 신기술을 응용한 물류 수,배송 화물추적 및 차량관제서비스 제공</p>
										</div>
									</div>
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">LBS</p>
											<span className="main-core-tit">LBS 서비스</span>
											<p className="fs-sm">GIS, GPS, 휴대폰 등 위치기반에 응용한 Tracking<br />서비스 제공</p>						  
										</div>
									</div>
								</div>
								<div className="col">
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">Mobile</p>
											<span className="main-core-tit">모바일</span>
											<p className="fs-sm mb-5">유뮤선 통합, 통신/방송 융합 등 컨버전스 시대에 대응하여 모바일 SI 및 모바일서비스 플랫폼 구축 등 최적의 서비스 제공</p>						  
										</div>
									</div>
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">Finance</p>
											<span className="main-core-tit">금융</span>
										  <p className="fs-sm mb-7">보험사의 긴급출동 서비스 제공</p>

										</div>
									</div>
									<div className="card-body mb-6 align-items-start">						
										<div>
											<span className="main-core-line"></span>
											<p className="main-core-blue">New Technology</p>
											<span className="main-core-tit">신기술 서비스</span>
										  <p className="fs-sm">다양한 산업분야에서 IT신기술을적용하여 미래 지향적인 편리한 IT서비스 제공 </p>						  
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	<Footer />
	
        </>
    )
}

export default Main;