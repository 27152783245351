import React from "react";
import { useNavigate, Link} from "react-router-dom";

import wipiaWImg from '../../assets/img/wipia-w.png';
import loadExternalJS from "../../js/loadExternalJS";
function AdminHeader() {
	loadExternalJS();

    return (<>
        {/* <!-- Navbar --> */}
			<header className="header navbar navbar-expand-lg navbar-dark position-absolute navbar-sticky">
				<div className="container px-3">
					<Link to="/comHisPrivList" className="navbar-brand pe-3">
						<img src={wipiaWImg} width="100" alt="WIPIA" />
					</Link>
					<div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
						<div className="offcanvas-header border-bottom border-light">
							<h5 className="offcanvas-title text-white">Menu</h5>
							<button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
						</div>
						<div className="offcanvas-body">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0">
								<li className="nav-item dropdown">
									<Link to="/comHisPrivList" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Company</Link>
									<ul className="dropdown-menu dropdown-menu-dark">
										<li><Link to="/comHisPrivList" className="dropdown-item">회사연혁</Link></li>
									</ul>
								</li>
								<li className="nav-item">
									<Link to="/adminLogin" className="nav-link">Logout</Link>
								</li>
							</ul>
						</div>
					</div>
				
					{/* <!-- 모바일 메뉴 버튼 --> */}
					<button type="button" className="navbar-toggler pb-5" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					
				</div>
			</header>
    
    </>);
}

export default AdminHeader;