import axios from "axios";

let contentType = "application/json";

const instance = axios.create({
    baseURL: "http://www.wipia.co.kr"
    //baseURL: "http://localhost:8080"
    //baseURL: "http://192.168.0.109:8080"
});

instance.interceptors.request.use(
    (config) => {
        const accessToken = sessionStorage.getItem("token");
        const refreshToken = sessionStorage.getItem("refreshToken");
        console.log("accessToken :: " + accessToken);
        console.log("refreshToken :: " + refreshToken);
        contentType = config.headers['Content-Type'];

        config.headers['X-AUTH-TOKEN'] = accessToken;
        config.headers['X-REFRESH-TOKEN'] = refreshToken;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 404) {
      console.log('404 페이지로 넘어가야 함!');
    }
    return response;
  },
  async (error) => {
    // token 만료
    if (error.response.status === 401) {
        sessionStorage.setItem("token", error.response.data["x-auth-new-token"]);
        //console.log("여길타나 ::: " + sessionStorage.getItem("token"));

        // refresh token 만료
        if(sessionStorage.getItem("token")=="undefined") {
            const response = {data: {rtnCd: "EXPIRE"}}
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("refreshToken");
            return response;
        } 
        // access token 만료
        else {
            const accessToken = sessionStorage.getItem("token");
            const refreshToken = sessionStorage.getItem("refreshToken");

            error.config.headers = {
                "Content-Type": contentType,
                "X-AUTH-TOKEN": accessToken,
                "X-REFRESH-TOKEN": refreshToken
            };

            const response = await axios.request(error.config);
            return response;
        }
    }
    return Promise.reject(error);
  }
);

export default instance;