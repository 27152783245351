import React from "react";
import Error from "../Ui/Error/Error.jsx";
import * as Common from "../Common/common.js";

function AuthRouter(props) {
    const {component} = props;

    if(Common.fn_authChek()) {
        return component;
    } else {
        return<Error/>
    }
}

export default AuthRouter;