import React from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../Component/Header";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

function Welfare() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (
        <div>
            <Header/>
            <br/>
            <ButtonGroup size="lg" className="mb-2">
                <Button onClick={()=>{navigate("/recruit/welfare")}}>복리후생</Button>
                <Button onClick={()=>{navigate("/recruit/recruitmentGuide")}}>채용안내</Button>
            </ButtonGroup>
            <br/>
            복리후생 화면입니다.
        </div>
    )
}

export default Welfare;