import React, {useState} from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Loading from "../Component/Loding.jsx";
import moment from "moment";
//import Button from 'react-bootstrap/Button';
import * as Common from "../../Common/common.js";
import { useNavigate } from 'react-router-dom';

import mainBg from "../../assets/img/bg-esg-03.jpg";
import subBg from "../../assets/img/bg-esg-06.jpg";

function HumanReport() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
    const [imgFile, setImgFile] = useState([]);
    const [emailInfo, setEmailInfo] = useState({
          gubun: "hr"
        , registDate: moment(new Date()).format("YYYY-MM-DD") 
        , registName: ""
		, registMail: ""
        , registTelNo: ""
        , registTitle: ""
        , registContent: ""
        , registRequire: ""
    });

    // 파일 정보 제외한 data 변경 값 set
    const handleChange = (e) => {
        
		const { value, name } = e.target;
        //console.log("name="+name+", value="+value)
		setEmailInfo({
			...emailInfo, // 기존 객체 복사
			[name]: value, // name 키를 가진 값을 value로 설정
		});
	}

    // 파일 변경 데이터 set
    const handleChangeFile = (event) => {
        setImgFile(event.target.files); 
    }

	//신고하기
	const fn_report = () => {
		setIsLoading(true);
		//alert();
		Common.fn_send(imgFile, emailInfo, "/humanRightMgmt", setIsLoading);
		
	}
    return (<>
	<main className="page-wrapper">
		<Header />
		
        <section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">ESG</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">위피아는 지속가능한 경영을 위해<br />사회적 역할과 책임을 다하고 신뢰받는 기업이 되기 위해 노력합니다.    
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				    <li className="breadcrumb-item">
					    <Link to="/">Home</Link>
				    </li>
				    <li className="breadcrumb-item">ESG</li>
				    <li className="breadcrumb-item">
                        <Link to="/humanRightMgmt" >인권경영 </Link>
                    </li>

                  <li className="breadcrumb-item active" aria-current="page">인권침해 및 고충 신고</li>
				</ol>
			</nav>
			
            <section className="container py-4">
				<div className="bg-dark bg-size-cover bg-position-center bg-repeat-0 position-relative overflow-hidden rounded-3 py-lg-3 py-xl-5 px-4 px-lg-5 px-xl-0" style={{backgroundImage: `url(`+subBg+`)`}} data-bs-theme="dark">
					<div className="row px-sm-5 py-4 my-1 my-md-3">
						<h4 className="h5 mb-3 fs-8">WIPIA</h4>
						<h2 className="h2 pb-1 pb-md-3 pb-lg-4" style={{fontWeight: "normal"}}>인권침해 신고 및 제보</h2>
					</div>
				</div>
			</section>

			{/* <!-- 인권침해 신고 --> */}
			<section className="container my-5 py-md-4 pt-2 pb-3">
				<h2 className="h3 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">인권침해 신고</h2>
				<div className="col-12">
					<p className="mb-0 h1-sub">- 위피아는 임직원의 인권침해 사실에 대해 신고합니다.<br />
					- 사실에 근거하지 않은 비방, 개인사생활과 관련한 사항은 조사 대상에서 제외하며, 조사시 추가 
					  필요사항을 요청드릴 수 있습니다.
					</p>
				</div>				
			</section>
			
			
			{/* <!-- 제보 방법 --> */}
			<section className="container my-2 py-lg-5 py-md-4 pt-2 pb-3 mb-6">
				<h2 className="h3 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">제보 방법</h2>				
				<div className="container my-2 my-md-4 py-2 mx-n3">
					<div className="row row-cols-1 row-cols-md-3">

						{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">우편신고</h2>
									<p className="mb-0 mt-3r">서울특별시 영등포구 경인로 775 1-507 ( 문래동3가 , 에이스하이테크시티)</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">유선신고</h2>
									<p className="mb-0 mt-3r">02-2038-8311</p>
								</div>
							</div>
						</div>
						
						{/* <!-- Item --> */}
						<div className="col my-sm-3 mb-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h7 d-inline-flex align-items-center mt-3">온라인신고</h2>
									<p className="mb-0 mt-3r">{/* E-mail: info@wipia.co.kr<br /> */} 홈페이지:www.wipia.co.kr</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>	

            {/* <Header/> */}
            {/* <br/>
            인권침해 신고 화면입니다. 
            <Table bordered>
                <tbody>
                    <tr>
                        <td>신고일</td>
                        <td colSpan={3}>
                            {emailInfo.registDate}
                        </td>
                    </tr>
                    <tr>
                        <td>신청인 성명</td>
                        <td>
                            <input type="text" name="registName" onChange={handleChange}/>
                        </td>
                        <td>연락처</td>
                        <td>
                            <input type="text" name="registTelNo" onChange={handleChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td>제목</td>
                        <td colSpan={3}>
                            <input type="text" name="registTitle" onChange={handleChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td>내용</td>
                        <td colSpan={3}>
                            <input type="text" name="registContent" onChange={handleChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td>요구사항</td>
                        <td colSpan={3}>
                            <input type="text" name="registRequire" onChange={handleChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td>파일선택</td>
                        <td colSpan={3}>
                            <input type="file" onChange={handleChangeFile} multiple/>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Button onClick={()=>{Common.fn_send(imgFile, emailInfo, "/esg/humanRightMgmt")}}>저장º신고</Button>{" "} */}

            <section className="bg-secondary position-relative pt-2 ">
				<div className="container pb-2 pb-md-4 pb-lg-5 py-7">
					<div className="row justify-content-center text-center pt-xl-2 pb-4 mb-1 mb-lg-3">
						<div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
						  <h2 className="mb-4">인권침해 신고</h2>
						  <p className="text-muted mb-0">※회사는 신고자의 신분을 최우선으로 보호합니다.<br />
						  	※인권침해 신고를 위해 수집된 개인정보는 목적 외의 용도로 사용하지 않겠습니다.<br />
							  ※ 사실에 근거하지 않은 비방, 개인사생활과 관련한 사항은 조사 대상에서 제외하며, 조사시 추가 필요사항을 요청드릴 수 있습니다.
							</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-8 col-md-10">
							<form className="row needs-validation" noValidate>
								<div className="col-sm-6 mb-4">
								  <label htmlFor="fn" className="form-label">제보일</label>
								  <input type="text" id="fn" className="form-control" value={emailInfo.registDate} required disabled/>
								</div>
								<div className="col-sm-6 mb-4">
								  <label htmlFor="email" className="form-label">제보자 이메일</label>
								  {/* <input type="email" id="email" className="form-control" required /> */}
                                  <input className="form-control" type="text" name="registMail" onChange={handleChange} required/>
								</div>
								<div className="col-sm-6 mb-4">
								  <label htmlFor="email" className="form-label">제보자 성명</label>
								  {/* <input type="email" id="email" className="form-control" required /> */}
                                  <input className="form-control" type="text" name="registName" onChange={handleChange} required/>
								</div>
								<div className="col-sm-6 mb-4">
								  <label htmlFor="phone" className="form-label">연락처</label>
								  {/* <input type="text" id="phone" className="form-control" /> */}
                                  <input className="form-control" type="text" name="registTelNo" onChange={handleChange}/>
								</div>
								{/* <div className="col-sm-12 mb-4">
								  <label htmlFor="city" className="form-label">이메일 주소</label>
								  <input type="text" id="company" className="form-control" />
								</div> */}
								<div className="col-sm-12 mb-4">
								  <label htmlFor="company" className="form-label">제목</label>
								  {/* <input type="text" id="company" className="form-control" /> */}
                                  <input className="form-control" type="text" name="registTitle" onChange={handleChange}/>
								</div>												
								<div className="col-12 mb-4">
								  <label htmlFor="message" className="form-label">내용</label>
								  {/* <textarea id="message" className="form-control" rows="4" required></textarea> */}
								  {/* <div className="invalid-feedback">Please, enter your message!</div> */}
                                  <textarea className="form-control" type="text" name="registContent" onChange={handleChange} rows="4"/>
								</div>
								<div className="col-12 mb-4">
								  <label htmlFor="message" className="form-label">요구사항</label>
                                  <textarea className="form-control" type="text" name="registRequire" onChange={handleChange} rows="4"/>
								</div>
								<div className="col-sm-12 mb-4 d-sm-inline-flex">
									 <div className="col-9"><label htmlFor="company" className="form-label">증빙(선택)</label>
										{/* <input type="email" className="form-control rounded-start" required="" /> */}
                                        <input type="file" className="form-control rounded-start" onChange={handleChangeFile} multiple/>
                                    </div> 
									{/* <div className="col-3 mt-7 ms-2">
                                        <button type="submit" className="btn btn-outline-primary" style={{height: "40px"}}>파일첨부</button>
                                    </div>
                                    <div className="col-3 mt-7 ms-2">
                                        <button type="submit" className="btn btn-outline-primary" style={{height: "40px"}}>파일첨부</button>
                                    </div> */}
                                    {/* <div className="col-3 mt-7 ms-2">
                                        <input type="file" className="btn btn-outline-primary" onChange={handleChangeFile} multiple/>
                                    </div> */}

								</div>
								<div className="col-12 text-center pt-2 pt-md-3 pt-lg-4 mb-7">
								  {/* <button type="submit" className="btn btn-primary shadow-primary btn-lg">신고하기</button> */}
                                  <button type="button" className="btn btn-primary shadow-primary btn-lg" onClick={()=>{fn_report()}}>신고하기</button>
								</div>
					 		</form>
						</div>
					</div>
				</div>

				{/* <!-- BG shape --> */}
				<div className="position-absolute end-0 bottom-0 text-primary">
				  <svg width="469" height="343" viewBox="0 0 469 343" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.08" fillRule="evenodd" clipRule="evenodd" d="M273.631 680.872C442.436 768.853 639.315 708.216 717.593 558.212C795.871 408.208 732.941 212.157 564.137 124.176C395.333 36.195 198.453 96.8326 120.175 246.836C41.8972 396.84 104.827 592.891 273.631 680.872ZM236.335 752.344C440.804 858.914 688.289 788.686 789.109 595.486C889.928 402.286 805.903 159.274 601.433 52.7043C396.964 -53.8654 149.479 16.3623 48.6595 209.562C-52.1598 402.762 31.8652 645.774 236.335 752.344Z" fill="currentColor"/><path opacity="0.08" fillRule="evenodd" clipRule="evenodd" d="M298.401 633.404C434.98 704.59 598.31 656.971 664.332 530.451C730.355 403.932 675.946 242.827 539.367 171.642C402.787 100.457 239.458 148.076 173.435 274.595C107.413 401.114 161.822 562.219 298.401 633.404ZM288.455 652.464C434.545 728.606 611.369 678.429 683.403 540.391C755.437 402.353 695.402 228.725 549.312 152.583C403.222 76.4404 226.398 126.617 154.365 264.655C82.331 402.693 142.365 576.321 288.455 652.464Z" fill="currentColor"/></svg>
				</div>
			</section>
		</main>
	<Footer />

	<Loading active={isLoading}/>
        </>
    )
}

export default HumanReport;