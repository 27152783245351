import React from "react";

import loadingImg from '../../assets/img/loading.gif';

function Loading(props){
    
    return (<>
        <div className={props.active ? 'loading active' : 'loading'}>
            <div className="loading_inner"> 
                <img src={loadingImg} />
            </div>
        </div>
    </>);
}

export default Loading;



