import React from "react";
import { Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-recruit.jpg";
import challengerIc from "../../assets/img/icons/award.svg";
import creatorIc from "../../assets/img/icons/idea.svg";
import coworkerIc from "../../assets/img/icons/team.svg";


function RecruitmentGuide() {
	
    return (<>
        {/* // <div>
        //     <Header/>
        //     <br/>
        //     <ButtonGroup size="lg" className="mb-2">
        //         <Button onClick={()=>{navigate("/recruit/welfare")}}>복리후생</Button>
        //         <Button onClick={()=>{navigate("/recruit/recruitmentGuide")}}>채용안내</Button>
        //     </ButtonGroup>
        //     <br/>
        //     채용안내 화면입니다.
        // </div> */}
	<main className="page-wrapper">
		<Header />
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
					  <div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">인재채용</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 고객의 행복과 풍요로움을 먼저 생각하는 스마트 IT 서비스 전문 기업입니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">Recruit</li>
				</ol>
			</nav>
			
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">채용안내</h2>
				<div className="col-12">
					<p className="mb-0 h1-sub">위피아는 고객의 경쟁력과 가치창조 실현을 위하여 IT Consulting 및 LBS, Mobile, 물류, 환경모니터링 분야의 특화된 솔루션 공급과 창의적 지식과 경쟁력으로 고객에게 최적화된 스마트 IT 서비스 기업이 되도록 최선의 노력을 다 하겠습니다.</p>
				</div>
				
				 <div className="d-flex align-items-center mt-3">
					<i className="bx bx-check-circle fs-xl text-primary me-2"></i>
					상시접수 : 이메일 (info@wipia.co.kr)
				  </div>
			</section>
			
			{/* <!-- 비전및 목표 --> */}
			<section className="bg-secondary py-5">
				 <div className="bg-secondary rounded-3 my-2 my-md-4 px-3 px-sm-6">
					<h1 className="h2 mb-sm-5 py-3 text-lg-center text-lg-start" style={{color: "#002b7b"}}>WIPIA 인재상</h1>
					<div className="container bg-white rounded-3 my-2 my-md-4 py-5 px-3 px-sm-6">
						<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gx-3 gx-md-4 mt-n2 mt-sm-0">
							{/* <!-- 친환경 경영 --> */}
							<div className="col pb-1 pb-lg-3 py-4">
								{/* <!-- Item --> */}
								<div className="border-end-lg px-2">
								  <div className="text-center">
									<img src={challengerIc} width="48" alt="Bulb icon" className="d-block mb-4 mx-auto" />
									<h4 className="mb-2 pb-1">Challenger</h4>
									<p className="mx-auto" style={{maxWidth: "315px"}}>열정과 도전정신을 가지고 끊임없는 성장을 추구하는 사람 </p>
								  </div>
								</div>
							</div>

							{/* <!-- 사회 --> */}
							<div className="col pb-1 pb-lg-3 py-4">
								<div className="border-end-lg px-2">
								  <div className="text-center">
									<img src={creatorIc} width="48" alt="Award icon" className="d-block mb-4 mx-auto" />
									<h4 className="mb-2 pb-1">Creator</h4>
									<p className="mx-auto" style={{maxWidth: "320px"}}>창조적이며 기존의 상식과 틀을 과감히 깨는 혁신적인 사람</p>
								  </div>
								</div>
							</div>

							{/* <!-- 지배구조 --> */}
							<div className="col pb-1 pb-lg-3 py-4">
								{/* <!-- Item --> */}
								<div className="px-2">
								  <div className="text-center">
									<img src={coworkerIc} width="48" alt="Team icon" className="d-block mb-4 mx-auto" />
									<h4 className="mb-2 pb-1">Coworker</h4>
									<p className="mx-auto" style={{maxWidth: "300px"}}>타인을 존중하고 동료간에 협조할 줄 아는 역할과 책임을 다하는 사람</p>
								  </div>
								</div>
							</div>
					  </div>
				   </div>
					 {/* <div className="pt-md-3 pt-2 text-center">
						  <a href="./resources/doc/wipia_recruit.doc" download="wipia_recruit.doc" className="btn btn-lg btn-primary w-sm-auto w-100">입사지원서 다운로드</a>
				   </div> */}
			  </div>				 
			</section>


			{/* <!-- 추진전략 --> */}
			<section className="container my-5 py-lg-5 py-md-4 pt-2 pb-3">
				<h2 className="h2 mb-4 py-xl-3 pb-lg-2 pb-md-0 pb-sm-2">기타 유의사항</h2>
				<p className="mb-md-0">서류전형, 면접 합격자는 E-mail, 전화 등으로 개별 통지</p>
				<div className="d-md-block pt-5 mt-2 pb-4">				  
				  <div className="row g-0 bg-secondary rounded-3">
					<div className="text-dark fw-semibold ps-4 pe-2 py-4 me-3">01 <span className="d-block fw-light">입사지원서 기재 사항 중 허위사실이 판명될 경우 합격 및 입사가 취소됨</span></div>
				  </div>
				  <div className="row g-0">
					<div className="text-dark fw-semibold ps-4 pe-2 py-4 me-3">02 <span className="d-block fw-light">접수된 서류는 일체 반환하지 않음</span></div>
				  </div>
				  <div className="row g-0 bg-secondary rounded-3">
					<div className="text-dark fw-semibold ps-4 pe-2 py-4 me-3">03 <span className="d-block fw-light">해외 여행에 결격 사유가 없는 자</span></div>
				  </div>
				</div>
			</section>		

		</main>
	<Footer />
        </>
    )
}

export default RecruitmentGuide;