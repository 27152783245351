import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-company-03.jpg";
import certi01 from "../../assets/img/certification-01.jpg";
import certi02 from "../../assets/img/certification-02.jpg";
import certi03 from "../../assets/img/certification-03.jpg";
import certi04 from "../../assets/img/certification-04.jpg";
import certi05 from "../../assets/img/certification-05.jpg";
import certi06 from "../../assets/img/certification-06.jpg";
import certi07 from "../../assets/img/certification-07.jpg";
import certi08 from "../../assets/img/certification-08.jpg";
import certi09 from "../../assets/img/certification-09.jpg";

function CoreCompetence() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
		
        {/* // <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">인증 및 협약</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 선도적인 기술력으로 여러 기관의 인증 및 협약을 진행하였습니다.</p>
						</div>
					</div>

				</div>
			</section>

            {/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link href="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item"> Company</li>
				  <li className="breadcrumb-item active" aria-current="page">인증 및 협약</li>
				</ol>
			</nav>

            <section className="mb-5">
				<div className="container my-xl-5 my-lg-4 pb-5">
					<div className="row my-2">
						<div className="col-lg-12 ">
							<div className="row row-cols-sm-3 row-cols-1 gy-4">

								{/* <!-- Item01 --> */}
								<div className="col px-4">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">정보통신공사업 등록</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi01} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item02 --> */}
								<div className="col px-4">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">벤처기업 확인서</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi02} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item03 --> */}
								<div className="col px-4">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">프로그램 등록</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi03} alt="Skin " style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item04 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">위치기반사업자</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi04} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item05 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">모바일 광고서비스 특허등록</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi05} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item06 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1" style={{letterSpacing: '-2px', fontSize: '1.1rem'}} >스마트 가로등 및 스마트 가로등을 이용한 범죄예방 시스템</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi06} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item07 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">자연어를 이용한 로봇제어시스템 특허권</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi07} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item08 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">현대오토에버 동반성장 협약사</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi08} alt="Skin" style={{mmaxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								{/* <!-- Item09 --> */}
								<div className="col px-4 py-3">
									<div className="card border-0 shadow-xlg position-relative rounded-3"> 
										<div className=" mt-4 mx-3 mb-2 pt-0 ">
											<span className="ms-2">WIPIA</span>
											<h3 className="h7 ms-2 mt-1">기업부설연구소 인증서</h3>
										</div>
										<div className="text-center mb-4">
											<img src={certi09} alt="Skin" style={{maxWidth: "90%"}} />
										</div>
									</div>
								</div>
								
								
								
								
							</div>
						</div>
					</div>
				</div>
			</section>

		</main>
	<Footer />
        </>
    )
}

export default CoreCompetence;