import React from "react";
import { Link } from "react-router-dom";

import wipiaImg from '../../assets/img/wipia.png';

function Footer(props) {

    return (
        <>
            <footer className="footer pt-5 pb-4">
			    <div className="container">
				    <div className="row px-3">
				    	<div className="navbar-brand text-dark p-0 me-0 mb-5">
				    		<img src={wipiaImg} width="80" alt="WIPIA" />
			    		</div>
			    		<div className="p-0 mb-3">
			    			<Link to="/privacy" className="footer-link px-0">개인정보보호방침</Link>
			    			<span className="footer-link-line"></span>
			    			{/* <Link to="sitemap.html" className="footer-link px-0">사이트맵</Link> */}
							<a href="" className="footer-link px-0" data-bs-toggle="modal" data-bs-target="#modalSitemap">사이트맵</a>
			    			<span className="footer-link-line"></span>
			    			<Link to="/locationInfo" className="footer-link px-0">Contact Us</Link>
			    		</div>
			    		<div className="fs-sm p-0 mb-1 fw-medium">서울특별시 영등포구 경인로 775, 1동 507호(문래동3가, 에이스하이테크시티)<span className="footer-link-line"></span>대표번호 : 02-2038-8311<span className="footer-link-line"></span>팩스번호 : 02-2038-8322</div>
			    		<div className="fs-sm mb-4 p-0 fw-medium">COPYRIGHT ⓒ 2024 WIPIA CO., LTD. ALL RIGHTS RESERVED.</div>
			    	</div>
			    </div>
		    </footer>
			
			{/* <!-- Sitemap modal--> */}
        <div className="modal fade" id="modalSitemap" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-fullscreen" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-2 fw-medium mb-1"><img src={wipiaImg} width="90" alt="WIPIA" /></h5>
                <button className="btn-close mt-5 mb-1" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="my-2 my-md-4 py-2 px-3 px-sm-4">
					<div className="row row-cols-1 row-cols-md-5">

						{/* <!-- Company --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Company</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="comOverview">회사소개</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="comHisPubList">회사연혁</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="coreCompetence">인증 및 협약</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- ESG --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">ESG</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="visionObjective">ESG</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="enviroMgmt">환경경영</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="HumanRightMgmt">인권경영</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="safetyMgmt">안전보건경영</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="ethicalMgmt">윤리경영</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="socialContirb">사회공헌</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- Business --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Business</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="iTConsulting">IT Consulting</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="systemIntegration">System Integration</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="solution">Solution</a></li>
										<li className="d-flex pb-1 mb-2 fw-medium "><a href="iTService">IT Service</a></li>
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="constructionCase">구축사례</a></li>
									</ul>
								</div>
							</div>
						</div>
						
						{/* <!-- Contact US --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Contact US</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="locationInfo">Contact Us</a></li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- Recruit --> */}
						<div className="col py-3 my-sm-3">
							<div className="card h-100">
								<div className="card-body my-md-4 py-xl-2 py-md-0 py-sm-2">
									<span className="line-fill"></span>
									<h2 className="h4 d-inline-flex align-items-center mt-3">Recruit</h2>
									<ul className="list-unstyled pb-2 pb-lg-0 py-4 mb-lg-5">
									  <li className="d-flex pb-1 mb-2 fw-medium "><a href="recruitmentGuide">Recruit</a></li>
									</ul>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
              </div>
             
            </div>
          </div>
        </div>

    		{/* Back to top button  */}
    		<a href="#top" className="btn-scroll-top mb-4" data-scroll>
    			<span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
    			<i className="btn-scroll-top-icon bx bx-chevron-up"></i>
    		</a>
        
        </>
    );
}

export default Footer;