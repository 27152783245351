import React from "react";
import { useNavigate , Link} from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-busuness-01.jpg";
import cons01 from "../../assets/img/consulting-01.jpg";
import cons02 from "../../assets/img/consulting-02.jpg";
import cons03 from "../../assets/img/consulting-03.jpg";
import cons04 from "../../assets/img/consulting-04.jpg";
import CallWipia from "./CallWipia";

function ITConsulting() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">IT Consulting</h1>
							<p className="text-body mx-auto pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 고객의 Needs에 부합하는 e-Business 시스템을 구축합니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item"> Business</li>
				  <li className="breadcrumb-item active" aria-current="page">IT Consulting</li>
				</ol>
			</nav>

			{/* <!-- Item 01 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons01} alt="Image" />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">01</p>
						<h1 className="h2 mb-sm-4 con-left">Experience</h1>
						<p className="fs-5  con-left">축적된 경험을 통해  최적의 컨설팅을 제공합니다.</p>
					</div>
				</div>
			</section>


			{/* <!-- Item 02 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons02} alt="Image" />
						</div>
					</div>
					 <div className="col-md-6 mb-4">
						<p className="con-blue">02</p>
						<h1 className="h2 mb-sm-4">Knowhow</h1>
						<p className="fs-5" style={{maxWidth: "26rem"}}>프로젝트 수행과정에서 생긴 노하우로 최적의 컨설팅을 제안합니다.</p>
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons03} alt="Image" />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">03</p>
						<h1 className="h2 mb-sm-4 con-left">Professional</h1>
						<p className="fs-5  con-left" style={{maxWidth: "28rem"}} >전문적인 인력과 솔루션으로 현재와 미래의 요건을 충족하는 IT 컨설팅이 되기 위해 노력합니다.</p>
							</div>
				</div>
			</section>


			{/* <!-- Item 04 --> */}
			<section className="container mb-7">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons04} alt="Image" />
						</div>
					</div>
					<div className="col-md-6">
						<p className="con-blue">04</p>
						<h1 className="h2 mb-sm-4">Needs</h1>
						<p className="fs-5" style={{maxWidth: "28rem"}}>고객이 원하는 형태의 업무 시스템 구축, 유지보수 및 아웃소싱등 다양한 상황을 반영하는 컨설팅을 제공합니다.</p>
					</div>
				</div>
			</section>

            <CallWipia />
				
		</main>
	<Footer />

        </>
    )
}

export default ITConsulting;