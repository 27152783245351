import React, {useEffect} from "react";

function Error() {

    useEffect(() => {
        alert("접근권한이 없습니다.");
    },[]);

    return (
        <div>
            접근권한이 없습니다. 
        </div>
    )
}

export default Error;