import React , {useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import {useCookies} from "react-cookie";
import { ArgContext } from "../../Context/ArgProvider"
import instance from "../../axios_interceptor.js";

import mainBg from "../../assets/img/wipia.png";
import subBg from "../../assets/img/bg-login.jpg";
function AdminLogin() {

    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    // 전역변수 불러오기
    const {argInfo, setArgInfo} = useContext(ArgContext);

    // 쿠키 객체
    const [cookies, setCookie, removeCookie] = useCookies(["loggedIn"]);
    
    // 로그인 정보 data
    const [loginInfo, setLoginInfo] = useState({
          userId: ""
        , userPw: ""
    });

    // 로딩 후 최초 한번
    useEffect(()=> {
        // 아이디 쿠키 저장 여부 확인 후 set
        if(cookies.loggedIn!=null) {
            // 아이디가 있을 경우 set
            if(cookies.loggedIn!="") {
                setLoginInfo({userId:cookies.loggedIn});
            } 
            // 아이디가 없을 경우 쿠키 삭제
            else {
                removeCookie("loggedIn"); 
            }
        }
    }, []);

    // 로그인 처리
    const fn_login = () => {
        // 로그인 통신
        const response = instance.post("/login", { adminId: loginInfo.userId,adminPwd: loginInfo.userPw}, {headers: {"Content-Type": "application/json"}});
        console.log("login start");
        // 통신 결과
        response.then((res) => {console.log("login called");
            // 로그인 성공
            if(res.data.rtnCd=="SUCCESS") {console.log("login SUCCESS");
                // 로그인 성공시 토큰값 localStorage set
                sessionStorage.setItem("token", res.data.rtnAccessToken);
                sessionStorage.setItem("refreshToken", res.data.rtnRefreshToken);
                
                // 토큰값 localStorage 컨텍스트에 set
                setArgInfo({token:sessionStorage.getItem("token"), refreshToken:sessionStorage.getItem("refreshToken")});
                
                // 로그인 유효 여부 sessionStorage set
                sessionStorage.setItem("loginCk", true);

                // 메인으로 이동
                navigate("/comHisPrivList");
            } 
            // 로그인 실패
            else {
                alert("관리자에게 문의바랍니다.");
            }
        });
    }

    // input 변경 값 set
    const handleChange = (e) => {
		const { value, name } = e.target;
		setLoginInfo({
			...loginInfo, // 기존 객체 복사
			[name]: value, // name 키를 가진 값을 value로 설정
		});
	}

    // ID SAVE 변경 값 set
    const handleChangeCk = (e) => {
        // 체크된 경우 쿠키값 set
        if(e.target.checked) {
            const time = 3600*24*7; //1시간
            const expiration = new Date(Date.now() + time * 1000);
            setCookie("loggedIn", loginInfo.userId, { path: '/' , expires: expiration });
        }
        // 체크 해제한 경우 쿠키 제거
        else {
            removeCookie("loggedIn"); 
        }
	}

    return (
        // <div style={{position:"relative", height:"300px"}}>
        //     <div style={{position:"absolute", height:"0px", top:"70%", left:"50%", transform:"translate(-50%, -50%)"}}>
        //         <Form style={{backgroundColor:"#ccffff", padding:"10px"}}>
        //             <Form.Group className="mb-3" controlId="formBasicEmail">
        //                 <Form.Control type="text" placeholder="Id" onChange={handleChange} name="userId" value={loginInfo.userId || ""}/>
        //             </Form.Group>

        //             <Form.Group className="mb-3" controlId="formBasicPassword">
        //                 <Form.Control type="password" placeholder="Password" onChange={handleChange} name="userPw" value={loginInfo.userPw || ""}/>
        //             </Form.Group>
        //             <Form.Group className="mb-3" controlId="formBasicCheckbox" style={{float:"right"}}>
        //                 <Form.Check type="checkbox" label="ID SAVE" onChange={handleChangeCk} checked={cookies.loggedIn!=null}/>
        //             </Form.Group>
        //             <Form.Group className="mb-3" controlId="formBasicCheckbox">
        //                 <Button as="input" type="button" value="로그인" style={{width: "300px"}} onClick={fn_login}/>
        //             </Form.Group>
        //         </Form>
        //     </div>
        // </div>
        <>
        <main className="page-wrapper" style={{height : "100%"}}>
			
            <section className="position-relative h-100 pb-4">

               {/* <!-- Sign in form --> */}
               <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100">
                   
                 <div className="w-100 align-self-end pt-1 pt-md-4 pb-4" style={{maxWidth: "526px"}}>
                   
                   <h1 className="text-center text-xl-start my-5">Admin Login</h1>
                   
                   <form className="needs-validation mb-2" noValidate>
                     <div className="position-relative mb-4">
                       <label htmlFor="userId" className="form-label fs-base">아이디</label>
                       <input type="text" id="userId" onChange={handleChange} name="userId" className="form-control form-control-lg" required value={loginInfo.userId || ""}/>
                       <div className="invalid-feedback position-absolute start-0 top-100">아이이를 입력해주세요.</div>
                     </div>
                     <div className="mb-4">
                       <label htmlFor="password" className="form-label fs-base">비밀번호</label>
                       <div className="password-toggle">
                         <input type="password" id="userPw" className="form-control form-control-lg" required onChange={handleChange} name="userPw" value={loginInfo.userPw || ""}/>
                         <label className="password-toggle-btn" aria-label="Show/hide password">
                           <input className="password-toggle-check" type="checkbox" />
                           <span className="password-toggle-indicator"></span>
                         </label>
                         <div className="invalid-feedback position-absolute start-0 top-100">비밀번호를 입력해주세요.</div>
                       </div>
                     </div>
                     <div className="mb-4">
                       <div className="form-check">
                         <input type="checkbox" id="remember" className="form-check-input" onChange={handleChangeCk} checked={cookies.loggedIn!=null}/>
                         <label htmlFor="remember" className="form-check-label fs-base">아이디 저장</label>
                       </div>
                     </div>
                     {/* <button type="submit" className="btn btn-primary shadow-primary btn-lg w-100 mt-3">Sign in</button> */}
                     <button className="btn btn-primary shadow-primary btn-lg w-100 mt-3" as="input" type="button" value="로그인" style={{width: "300px"}} onClick={fn_login}>로그인</button>
                   </form>
                   
               
                   
                 </div>
                 <div className="w-100 align-self-end">
                   <span className="mb-3">	<img src={mainBg} width="60" alt="WIPIA" /></span>
                   <p className="nav d-block fs-xs text-center text-xl-start pb-2 mb-0 mt-2">
                     COPYRIGHT ⓒ 2013 WIPIA CO., LTD. ALL RIGHTS RESERVED. 
                   </p>    
                 </div>
               </div>

               {/* <!-- Background --> */}
               <div className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block" style={{backgroundImage: `url(`+subBg+`)`}}></div>
            </section>
       </main>
        </>
    );
}

export default AdminLogin;