import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-busuness-01.jpg";
import busi05 from "../../assets/img/business-05.jpg";
import busi06 from "../../assets/img/business-06.jpg";
import busi07 from "../../assets/img/business-07.jpg";
import busi08 from "../../assets/img/business-08.jpg";
import CallWipia from "./CallWipia";

function BusinessArea() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (<>
	<main className="page-wrapper">
		<Header />
        {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">System Integration</h1>
							<p className="text-body pb-xl-2 pb-md-0 pb-sm-2 display-2-sub col-sm-8">WIPIA는 고객의 사업목표를 효과적으로 지원할 수 있도록 웹 및 모바일 통합 IT 서비스를 제공합니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">Business</li>
				  <li className="breadcrumb-item active" aria-current="page">System Integration</li>
				</ol>
			</nav>

			{/* <!-- Item 01 --> */}
			<section className="container mt-8">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={busi05} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">01</p>
						<h1 className="h2 mb-sm-4 con-left">Analyze</h1>
						<p className="fs-5 con-left" style={{maxWidth: "29rem"}} >고객이 원하는 Needs에 부합한 시스템 개발을 위해서 철저한 분석과 설계에 최선을 다합니다.</p>
					</div>
				</div>
			</section>


			{/* <!-- Item 02 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={busi06}/>
						</div>
					</div>
					 <div className="col-md-6 mb-4">
						<p className="con-blue">02</p>
						<h1 className="h2 mb-sm-4">Development</h1>
						<p className="fs-5" style={{maxWidth: "30rem"}}>각 분야별 전문적인 인력 구성으로 어플리케이션 개발간 선제적인 위험관리와 제품의 품질 확보를 위해 노력합니다.</p>
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={busi07} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">03</p>
						<h1 className="h2 mb-sm-4 con-left">Operate</h1>
						<p className="fs-5  con-left" style={{maxWidth: "27rem"}} >어플리케이션의 지속적인 개선을 목표로 시스템 운영간 안정성과 신뢰성을 제공합니다.</p>
							</div>
				</div>
			</section>


			{/* <!-- Item 04 --> */}
			<section className="container mb-7">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={busi08} />
						</div>
					</div>
					<div className="col-md-6">
						<p className="con-blue">04</p>
						<h1 className="h2 mb-sm-4">Maintenance</h1>
						<p className="fs-5" style={{maxWidth: "28rem"}}>시스템 결함 및 고객의 요구사항에 대해 즉각적인 대응체계를 통해 통합 품질관리를 실천하고 있습니다.</p>
					</div>
				</div>
			</section>
    
        	<CallWipia />
		</main>
	<Footer />
    </>    
    )
}

export default BusinessArea;