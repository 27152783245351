import React, {useEffect, useState, useContext} from "react";
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
//import ListItem from "../Component/ListItem.jsx";
import instance from "../../axios_interceptor.js";
//import Header from "../Component/Header";
import AdminHeader from "../Component/AdminHeader.jsx";
import AdminFooter from "../Component/AdminFooter.jsx";

import mainBg from "../../assets/img/bg-busuness-01.jpg";


const PaginationBox = styled.div`
    .pagination { display: flex; justify-content: center; margin-top: 15px;}
    ul { list-style: none; padding: 0; }
    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem; 
    }
    ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
    ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
    ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
    ul.pagination li.active a { color: white; }
    ul.pagination li.active { background-color: #337ab7; }
    ul.pagination li a:hover,
    ul.pagination li a.active { color: blue; }
    `;

function ComHisPrivList() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();
    
    // 페이징 관련 변수
    const [page, setPage] = useState(1);
    const [items, setItems] = useState(10);
    const [totCnt, setTotCnt] = useState(0);

    // 검색 조건
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [taskContent, setTaskContent] = useState();

    // 연혁 리스트
    const [comYearList, setComYearList] = useState([]);
    
    // 월 조회
    useEffect(() => {
        axios.post("/selectYear")
        .then((response) => {
            console.log(response.data.rtnData)
            setYearList(response.data.rtnData);
        })
        .catch((error) => {
        // 예외 처리
        })
    }, []) 

    // 회사 연혁 조회하기
    const fn_selectAdminYearList = () => {
        const response = instance.post("/admin/selectAdminYearList"
            , { startPageNum : page, rnum : items, year : year, month : month, taskContent : taskContent}
            , {headers: {"Content-Type": "application/json"}});
        response.then((res) => {
            if(res.data.rtnCd=="SUCCESS") {
                setComYearList(res.data.rtnData);
                setTotCnt(res.data.rtnTotCnt);
                console.log(res.data);
            } else if(res.data.rtnCd=="EXPIRE") {
                if(window.confirm("로그인 세션이 만료되었습니다.")) navigate("/adminLogin");
            } else {
                alert("관리자에게 문의바랍니다.");
            }
        });
    }

    // 페이지 변경 이벤트
    const handlePageChange = (page) => { 
        setPage(page); 
    }

    // 현재 페이지 변경 되면 다시 재조회
    useEffect(() => {
        fn_selectAdminYearList();
    }, [page]);

    // 등록
    const fn_insert = () => {
        navigate("/comHisPrivWrite", {
            state: {
                gubun: "insert"
            }
        });
    }

    const handleYearChange = (e) => {
        setYear(e.target.value);
    }

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    }

    const handleContentChange = (e) => {
        setTaskContent(e.target.value);
    }

    return (<>
        {/* <div>
            <AdminHeader/>
            <br/>
            관리자가 접근 가능한 회사 연혁 리스트 화면입니다.
            <br/>
            <div style={{float:"right", margin:"10px"}}>
                <Button onClick={()=>{navigate("/comHisPubList")}}>공통 회사 연혁목록</Button>
            </div>
            <Button onClick={()=>fn_insert()}>등록</Button>
            <table>
                <colgroup>
                    <col width="10%;" />
                    <col width="10%;" />
                    <col width="10%;" />
                    <col/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>taskNo</th>
                        <th>년</th>
                        <th>월</th>
                        <th>프로젝트명</th>
                    </tr>
                    {comYearList.length!=0 && <ListItem listItem={comYearList} parentFnUpdate={fn_update}/>}
                </tbody>
            </table>

            <PaginationBox>
                <Pagination
                // 현재 보고있는 페이지 
                activePage={page}
                // 한페이지에 출력할 아이템수
                itemsCountPerPage={items}
                // 총 아이템수
                totalItemsCount={totCnt}
                // 표시할 페이지수
                pageRangeDisplayed={10}
                // 함수
                onChange={handlePageChange}>
                </Pagination>
            </PaginationBox>
        </div> */}

        <main className="page-wrapper">
            <AdminHeader />

            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link to="/comHisPrivList">Company</Link>
				  </li>
				  <li className="breadcrumb-item active" aria-current="page">회사연혁 목록</li>
				</ol>
			</nav>
			
			<section className="container pt-md-4 pb-md-5 mb-6">
				<h2 className="h2 mb-5 py-xl-3 pb-lg-2 pb-md-2 pb-sm-2">회사연혁 목록</h2>
				
				<div className="row align-items-end gy-3 mb-4 pb-lg-3 pb-1">
				  <div className="col-lg-12 col-md-9">
					<form className="row gy-2">
                        <div className="col-lg-2 col-sm-2">
                            <div className="d-flex align-items-center">
                            <select className="form-select" onChange={handleYearChange} value={year}>
                                <option value="">전체</option>
                                {/* <option value="processes-and-tools">2024</option>
                                <option value="processes-and-tools">2023</option>
                                <option value="processes-and-tools">2024</option> */}
                                {yearList.map((year)=> {
                                    return <option value={year}>{year}년</option>
                                })}
                            </select>
                            년
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-2">
                            <div className="d-flex align-items-center">
                            <select className="form-select" onChange={handleMonthChange} value={month}>
                                <option value="">전체</option>
                                <option value="1">1월</option>
                                <option value="2">2월</option>
                                <option value="3">3월</option>
                                <option value="4">4월</option>
                                <option value="5">5월</option>
                                <option value="6">6월</option>
                                <option value="7">7월</option>
                                <option value="8">8월</option>
                                <option value="9">9월</option>
                                <option value="10">10월</option>
                                <option value="11">11월</option>
                                <option value="12">12월</option>
                            </select>
                            
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control pe-5 rounded" placeholder="Search the blog..." onChange={handleContentChange} value={taskContent}/>
                                <i className="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 zindex-5 fs-lg"></i>
                            </div>
                            
                        </div>
                        <div className="col-lg-2 col-sm-2">
                        <button type="button" className="btn btn-primary fs-sm" style={{width: "100px", marginRight: "10px"}} onClick={fn_selectAdminYearList}>검색</button>
                        <button type="button" className="btn btn-primary fs-sm" style={{width: "100px"}} onClick={()=>fn_insert()} >등록</button>
                        </div>
					</form>	
				  </div>
				</div>
					
					{/* <!-- 테이블 -->	 */}
					<div className="policy-sec">
					
						<div className="table_box c_b wb_ka">

							<table className="line">
								<colGroup>
									{/* <col style={{width: "10%"}}></col> */}
									<col style={{width: "15%"}}></col>
									<col style={{width: "15%"}}></col>
									<col></col>
								</colGroup>
								<thead>
									<tr className="text-center">
										{/* <th>No</th> */}
										<th>년</th>
										<th>월</th>
										<th>프로젝트 명</th>
									</tr>
								</thead>
								<tbody>
                                    {comYearList.map((comYear) => {
                                        return <ListRow data = {comYear}/>
                                    })}
									{/* <tr>
										<td className="text-center">1</td>
										<td className="text-center">2023</td>
										<td className="text-center">01</td>
										<td>현대자동차 스마트</td>
									</tr>
									<tr>
										<td className="text-center">2</td>
										<td className="text-center">2023</td>
										<td className="text-center">01</td>
										<td>현대자동차 스마트</td>
									</tr>
									<tr>
										<td className="text-center">3</td>
										<td className="text-center">2023</td>
										<td className="text-center">01</td>
										<td>현대자동차 스마트</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					</div>
					<PaginationBox>
                        <Pagination
                            // 현재 보고있는 페이지 
                            activePage={page}
                            // 한페이지에 출력할 아이템수
                            itemsCountPerPage={items}
                            // 총 아이템수
                            totalItemsCount={totCnt}
                            // 표시할 페이지수
                            pageRangeDisplayed={10}
                            // 함수
                            onChange={handlePageChange}>
                        </Pagination>
                    </PaginationBox>
					{/* <!-- Pagination --> */}
					{/* <nav aria-label="Page navigation" className="mt-3">
					    <ul className="pagination justify-content-center pt-md-4 pt-2">
                            <li className="page-item">
                            <a href={()=>fn_prevPage()} className="page-link" aria-label="Previous page">
                                <i className="bx bx-chevron-left mx-n1"></i>
                            </a>
                            </li>
                            
                            <li className="page-item d-none d-sm-block">
                            <a href="#" className="page-link">1</a>
                            </li>
                            <li className="page-item active d-none d-sm-block" aria-current="page">
                            <span className="page-link">
                                2
                                <span className="visually-hidden">(current)</span>
                            </span>
                            </li>
                            <li className="page-item d-none d-sm-block">
                            <a href="#" className="page-link">3</a>
                            </li>
                            <li className="page-item d-none d-sm-block">
                            <a href="#" className="page-link">4</a>
                            </li>
                            


                            <li className="page-item">
                            <a href={()=>fn_nextPage()} className="page-link" aria-label="Next page">
                                <i className="bx bx-chevron-right mx-n1"></i>
                            </a>
                            </li>
					    </ul>
                    
                        <ul className="pagination justify-content-end pt-md-4 pt-2">
                            <li className="page-item disabled d-sm-block">
                                <span className="page-link text-body">{page} / {totCnt/items}</span>
                            </li>
                        </ul>
					</nav> */}
				
			</section>	
		</main>
        <AdminFooter />
        </>
    )
}

function ListRow(props) {
    const navigate = useNavigate();
    
    // 수정
    const fn_update = (data) => {
        navigate("/comHisPrivWrite", {
            state: {
                  gubun: "update"
                , data: data
            }
        });
    }

    return (<>
        <tr key={props.data.taskNo} onClick={() => fn_update(props.data)}>
			{/* <td className="text-center">{props.data.taskNo}</td> */}
			<td className="text-center">{props.data.year}</td>
			<td className="text-center">{props.data.month}</td>
			<td>{props.data.taskContent}</td>
		</tr>
    
    </>);
}
export default ComHisPrivList;