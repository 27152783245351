import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import Header from "../Component/Header";
import Footer from "../Component/Footer";

import mainBg from "../../assets/img/bg-busuness-03.jpg";
import cons01 from "../../assets/img/business-09.jpg";
import cons02 from "../../assets/img/business-10.jpg";
import cons03 from "../../assets/img/business-13.jpg";
import cons04 from "../../assets/img/business-12.jpg";
import CallWipia from "./CallWipia";

function BusinessArea() {
    // 화면 이동을 위한 네비게이터 설정
	const navigate = useNavigate();

    return (
        <>
	<main className="page-wrapper">
		<Header />
		
            {/* <!-- 메인 미이지 + 슬로건 --> */}
			<section className="jarallax bg-dark sub-img" data-jarallax data-speed="0.4" data-bs-theme="dark">
				<div className="jarallax-img-sub" style={{backgroundImage: `url(`+mainBg+`)`}}></div>
				<div className="d-flex flex-column">

					<div className="container position-relative zindex-5 pt-md-5 ">
						<div className="row mt-6">
						  <h1 className="display-2 mt-5 mb-md-4">Solution</h1>
							<p className="text-body pb-xl-2 pb-md-0 pb-sm-2 display-2-sub">WIPIA는 새로운 기술과 솔루션 개발을 위해 지속적으로 연구와 개발을 진행하고 있습니다.</p>
						</div>
					</div>

				</div>
			</section>

			{/* <!-- Breadcrumb --> */}
			<nav className="container py-4 pb-6 mb-md-2 mb-lg-5" aria-label="breadcrumb">
				<ol className="breadcrumb mb-0 float-end">
				  <li className="breadcrumb-item">
					<Link href="/">Home</Link>
				  </li>
				  <li className="breadcrumb-item">Business</li>
				  <li className="breadcrumb-item active" aria-current="page">Solution</li>
				</ol>
			</nav>

			{/* <!-- Item 01 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons01} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">01</p>
						<h1 className="h2 mb-sm-4 con-left">LBS</h1>
						<p className="fs-5 con-left" style={{maxWidth: "29rem"}} >GPS 좌표처리를 이용하여 다양한 위치 정보 솔루션을 제공합니다.</p>
					</div>
				</div>
			</section>


			{/* <!-- Item 02 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons02} />
						</div>
					</div>
					 <div className="col-md-6 mb-4">
						<p className="con-blue">02</p>
						<h1 className="h2 mb-sm-4">Mobile</h1>
						<p className="fs-5" style={{maxWidth: "29rem"}} >Web, Hybrid, native 등 다양한 플랫폼 환경을 제공합니다.</p>
					</div>
				</div>
			</section>

			{/* <!-- Item 03 --> */}
			<section className="container">
				<div className="row-non">
					<div className="col-md-6 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons03} />
						</div>
					</div>
					<div className="col-md-6 mb-4">
						<p className="con-blue con-left">03</p>
						<h1 className="h2 mb-sm-4 con-left">Embedded</h1>
						<p className="fs-5  con-left" style={{maxWidth: "28rem"}} >Embedded Test분야의 안정된 기술력을 보유하고 있습니다.</p>
							</div>
				</div>
			</section>


			{/* <!-- Item 04 --> */}
			<section className="container mb-7">
				<div className="row-non">
					<div className="col-md-6 order-md-2 pb-2 pb-md-0 mb-md-0">
						<div>
						  <img src={cons04} />
						</div>
					</div>
					<div className="col-md-6">
						<p className="con-blue">04</p>
						<h1 className="h2 mb-sm-4">Monitoring</h1>
						<p className="fs-5" style={{maxWidth: "28rem"}} >실시간 이벤트 모니터링 및 대응을 통해 고객이 만족할 수 있도록 최선을 다하고 있습니다.</p>
					</div>
				</div>
			</section>

            <CallWipia />
		</main>
	<Footer />
        </>
    )
}

export default BusinessArea;